<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="withPageTitle" :title="$t('label.colaboradores')" :titleAdd="$t('label.addColaborador')" :isAdd="true"/>
    <!-- filtro -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body p-0 box-dashboard">
            <div class="align-self-center">
              <form class="filtro_especie" @submit.prevent="search">
                <div class="row g-3 p-3 pb-0" v-if="!byInstitucional">
                  <div class="col-12 text-right">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                      <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
                    </div>
                  </div>
                </div>
                <div class="row g-3 p-3 pb-0">
                  <div class="col-12 col-lg-3">
                    <v-select :appendToBody="true" :options="vocSpecialtiesList" v-model="$store.state.formSearch.specialty_id" label="value" index="specialty_id"
                      :reduce="r => r.specialty_id" :clearable="false" class="bg-laranja-select vue-select" >
                      <template v-slot:selected-option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-lg-3">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.sobrenome')" v-model="$store.state.formSearch.surname"/>
                  </div>
                  <div class="col-12 col-lg-3">
                    <v-select :appendToBody="true" :options="statesList" v-model="$store.state.formSearch.state_id" label="state" index="state_id"
                      :reduce="r => r.state_id" :clearable="false" class="bg-laranja-select vue-select">
                      <template v-slot:selected-option="option">
                        {{ `${option.state}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.state}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-lg-3">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.cidade')" v-model="$store.state.formSearch.city"/>
                  </div>

                  <div class="col-12 col-lg-4 ml-auto text-right pt-1">
                    <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                      {{ $t('label.limpar') }}
                    </button>
                    <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                      {{ $t('label.buscar') }}
                    </button>
                  </div>
                </div>
              </form>

              <div class="row mt-1 mt-xl-2 p-3 bg_table_header">
                <div class="col-auto d-none d-sm-block">
                  <p class="mb-0 mt-2">
                    {{ $t('label.numRegistrosEncontrados') }}
                    <span class="laranja">{{$store.state.countList}}</span>
                  </p>
                </div>

                <div class="col-auto ml-auto text-right select_registro">
                  <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                    <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                    <option value="25">{{ $t('label.exibir25Reg') }}</option>
                    <option value="50">{{ $t('label.exibir50Reg') }}</option>
                    <option value="100">{{ $t('label.exibir100Reg') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /filtro -->

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <ColaboradoresTable @orderBy="orderBy" @search="search" :items="items" :withOrderBy="withOrderBy" :byInstitucional="byInstitucional" @actionByInstitucional="actionByInstitucional"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import ColaboradoresTable from './ColaboradoresTable.vue'
export default {
  name: 'Colaboradores',
  components: { ColaboradoresTable, PageTitle },
  props: {
    byInstitucional: {
      type: Boolean,
      default: false
    },
    withPageTitle: {
      type: Boolean,
      default: true
    },
    withOrderBy: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      items: [],
      vocSpecialtiesList: [{ specialty_id: '0', value: this.$t('label.especialidade') }],
      statesList: [{ state_id: '', state: this.$t('label.estado') }],
      dataOrderBy: {}
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderByNameAcs = this.$store.state.dataOrderBy.OrderByNameAcs
      this.$store.state.formSearch.OrderByNameDesc = this.$store.state.dataOrderBy.OrderByNameDesc
      this.$store.state.formSearch.OrderBySurnameAcs = this.$store.state.dataOrderBy.OrderBySurnameAcs
      this.$store.state.formSearch.OrderBySurnameDesc = this.$store.state.dataOrderBy.OrderBySurnameDesc
      this.$store.state.formSearch.OrderByIDAcs = this.$store.state.dataOrderBy.OrderByIDAcs
      this.$store.state.formSearch.OrderByIDDesc = this.$store.state.dataOrderBy.OrderByIDDesc
    },
    search: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Contact/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    },
    actionByInstitucional: function (item) {
      this.$emit('actionByInstitucional', item)
    },
    getVocSpecialties: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocSpecialties/getall', { params })
        .then(response => {
          this.vocSpecialtiesList = response.data.items.map(i => {
            const item = {
              specialty_id: i.specialty_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
          this.vocSpecialtiesList.unshift({ specialty_id: '0', value: this.$t('label.especialidade') })
        })
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
          this.statesList.unshift({ state_id: '', state: this.$t('label.estado') })
        })
    }
  },
  created: function () {
    this.search()
    this.getVocSpecialties()
    this.getStates()
  }
}
</script>
