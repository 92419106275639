export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmDelEspecie: 'Are you sure you want to delete THE SPECIES?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:'
  },
  exclusaoConta: {
    username: 'User',
    password: 'Password',
    login: 'Delete account',
    entrar: 'Send',
    descricao: 'Enter your login details in the form below to delete your Invasoras app account:',
    sucessoExlusao: 'Account deleted successfully.'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American network',
    baseDadosNacional: 'National Database',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Scientific committee',
    colabore: 'Collaborate',
    especiesMarinhas: 'Marine species',
    peixesAquario: 'Aquarium fishes',
    peixesAquicultura: 'Aquaculture fishes',
    pescaDesportiva: 'Sport fishing',
    pets: 'Pets',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'Find out more',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Authors',
    local: 'Location (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'Municipality (centroid)',
    referenciaLocal: 'Local reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'Museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political or administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    invasoras: 'Invasive',
    contidasBrasil: 'Contained',
    ausentesBrasil: 'Absent',
    deficiencia: 'Data deficient (DD)',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Habit',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Confidence level',
    resultadosAnalisesRisco: 'Risk assessment results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Habitats more susceptible to invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, impacts',
    consultaEspeciesManejo: 'Species and management',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Confidence level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    mensagemEnviada: 'Message sent successfully.',
    argentina: 'Argentina',
    brasil: 'Brazil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    equador: 'Ecuador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    exportar: 'Export',
    citacao_bd_new: 'Instituto Hórus 2024. Base de Dados Nacional de Espécies Exóticas Invasoras.',
    citacao_bd_new1: 'Available at https://bd.institutohorus.org.br/.',
    citacao_bd_new2: 'Accessed on XX/XX/XXXX.',
    adicionarAreaProtegida: 'Add Protected Area',
    configuracoes: 'Settings',
    grupoDeEmails: 'Email group',
    adicionarGrupoEmails: 'Add email group',
    emails: 'E-Mails',
    adicionarEmail: 'Add e-mail',
    grupoAtivo: 'Active group'
  },
  home: {
    destaque1: 'National Invasive Alien Species Database',
    destaqueDescricao1: 'Welcome! This database is built in collaboration with many people involved in nature conservation efforts in Brazil. Please visit!',
    destaque2: 'Since 2005 documenting invasive alien species in Brazil',
    destaqueDescricao2: 'This database has been developed and updated with funds from international projects. A Scientific Committee supports data management.',
    destaque3: 'How does it work?',
    destaqueDescricao3: 'This is an open access database. Please include a citation in your work! Professionals may request a login to contribute data.',
    destaque4: 'Our purpose',
    destaqueDescricao4: 'Provide data on invasive alien species in Brazil for governmental management, control programs and activities, scientific research, and the general public.',
    titulo: 'Find species, filter and export data',
    conteudo: 'Here you will find data on the native range of invasive alien species, ecological and biological characteristics, habitats invaded, places where species are invasive in the world and in Brazil, including a map of places of occurrence, history of introduction and use, vectors and pathways of introduction and spread, information on management and references. Filter and export data to different formats.',
    conteudoEspecies: 'Species lists, ecological traits, native range, where species are invasive in the world, vectors and pathways, risk assessment, uses, management options, places of occurrence in Brazil, references.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert or more information a place of occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Brazil. If you are working on a project, please let us know, so we can register it.',
    titulo1: 'Get to know invasive alien species in Brazil',
    conteudo1: 'Here you will find lists of invasive alien species in Brazil compiled by the reason for their introduction. Each page has an explanation on the group and links for additional sources of information.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin American Network',
    paragrafo1: 'This database network was established in 2005 as I3N (IABIN Invasives Information Network), a thematic network on invasive alien species then developed as part of the InterAmerican Biodiversity Information Network established by governments of countries in the Americas in 2001.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: 'Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2004);',
    marcador2: 'Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: 'Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'PhD. Eduardo Chacón-Madrigal, Professor at the University of Costa Rica. Researcher at the Biodiversity and Tropical Ecology Research Center. Co-coordinator of Flora Invasive and Naturalized of Central America.',
    marcador5: 'Dr. Diego Inclán, Executive Director of the National Institute of Biodiversity (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: 'Dra Lilian Ferrufino, Professor at the Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2004).',
    marcador5_3: 'Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5_4: 'Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (since 2005), and M.Sc. Marcelo Iturburu, Coordinator of the Invasive Alien Species Committee of the Ministry of Environment (since 2018).',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The database developers are:',
    marcador6: 'The Programmer João Scucato, from Curitiba, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'The Programmer Alejandro Moreno, from Argentina, who has provided support to the databases since 2006, and rescued data from Paraguay and Uruguay for this new version. He also developed the',
    marcador7_1: 'Common Platform',
    marcador7_2: 'for the South American countries in 2021.',
    marcador8: 'the Web Designer Rafael Moura and Programmer Thiago Lôbo, through',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'in Florianópolis, Brazil; ',
    marcador9: 'the programmer Leonardo Rotondano, in charge of developing the cell phone application for invasive species occurrences.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Support',
    paragrafo8: 'Year 2021',
    paragrafo9: '2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'National Database',
    titulo: 'The Brazil Invasive Alien Species Database',
    paragrafo1: 'This information platform was developed in 2004 by The Horus Institute in Brazil and the Universidad Nacional del Sur in Bahía Blanca, Argentina (Dr. Sergio Zalba), as part of an invasive species information network then known as I3N (IABIN Invasives Information Network). The network was then coordinated by Andrea Grosse, a Biologist who dedicated much time and effort to build the network, supported the development of the database structure and of several other products such as risk analysis protocols, manuals and training courses on invasive species. The other person who was very important for the growth of the network was Dr. Annie Simpson of the USGS, then in charge of the Global Invasive Species Information Network (GISIN), created to establish standards for invasive alien species data in order to facilitate the integration of databases at the global level and in several countries. The funds for the development of the national database were provided by the World Bank through the GEF for a project coordinated by the USGS that aimed to establish the InterAmerican Biodiversity Information Network (IABIN).',
    paragrafo2: 'The I3N Network was active between 2001 and 2011, when the project ended. During that time, the database structure was distributed to 21 countries in the Americas along with training courses on invasive species management and database usage: Argentina, Brazil, Uruguay, Chile, Paraguay, Bolivia, Peru, Ecuador, Colombia, Venezuela, Surinam, The Dominican Republic, the Bahamas, Jamaica, St. Lucia, Panama, Costa Rica, Honduras, El Salvador, Nicaragua, and Guatemala.',
    paragrafo3: 'Unfortunately, the majority of the databases has been lost over time. The ones in operation since the start belong to Argentina (Universidad Nacional del Sur, managed by Dr. Sergio Zalba); Brazil (Instituto Hórus, managed by Dr. Sílvia Ziller and Dr. Michele de Sá Dechoum), Uruguay (Universidad de la República em Montevidéu, managed by Dr. Ernesto Brugnoli, later joined by the Ministry of Environment Invasive Alien Species Committee, M.Sc. Marcelo Iturburu) and Jamaica (Institute of Jamaica, managed by Dr. Suzanne Davis). Cooperation between these database managers has never ceased to exist.',
    paragrafo4: 'In the year 2011, the database was updated from the original format in Microsoft Access to open software, in MySQL. On the occasion, many corrections and improvements were implemented thanks to feedback from users in several countries. The new version was then distributed to all countries in the network.',
    paragrafo5: 'Ten years later, in 2021, the BioBridge Initiative of the Convention on Biological Diversity in Montreal, Canada, provided funds for another update in a project prepared by Argentina, Brazil, Uruguay and Paraguay. Many improvements were implemented in order to facilitate data management, data collection and export by users. We hope that this version is more accessible and more user-friendly, and that the National Databases provide information for management at all levels, scientific research, and technical support to managers in the field. We also hope that it helps people avert the use of destructive species and promotes the use of indigenous species, especially to safeguard native biodiversity and the resilience of natural ecosystems in the Americas.',
    paragrafo6: 'We are very grateful to all who have collaborated with the database in some way, by providing or validating information, by appending data or any other of the many forms of support we have received over time. The richness of data available is the result of a large, collective effort. We are especially grateful to Dr. Michele de Sá Dechoum, Professor at the Federal University of Santa Catarina, Brazil, and Coordinator of the Lab on Ecology of Biological Invasions, Management and Conservation (',
    paragrafo7: ') for her partnership in working as database administrator and bringing in students to help update information through a university extension project.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and sources of reference',
    criterio: 'Criteria for adding new species',
    avaliacao: 'Data asssessment and review',
    validacao: 'Data validation',
    exclusao: 'Account deletion',
    paragrafo1: 'The concepts used in this database are aligned with the concepts used in the International Convention on Biological Diversity:',
    marcador1: 'native species – species found within its native range, where it evolved and is part of a biological community;',
    marcador2: 'alien (non-native) species – species outside its known native range (past or present);',
    marcador3: 'invasive alien species – species that threaten habitats, ecosystems or other species, generally causing impacts and changes in natural environments.',
    paragrafo2: 'The Brazil Invasive Alien Species Database lists species that are present in Brazil and have a history of invasion in the country. Records of occurrence in Brazil are provided for all species. There may be exceptions in the case of species of imminent risk of introduction to the country, to support early detection and rapid response. Other lists may be developed for species that have been introduced, have a history of invasion elsewhere, but have not been observed in the wild in the country. ',
    paragrafo3: 'The species contained in the database must not be considered equally invasive all over the country. They tend to invade certain habitats with higher compatibility or aggressiveness. For this reason, the habitat types each species invades are indicated, as well as places where they occur in the country. Species reported as invasive without geographic reference are not included until at least one or a few locations are confirmed.',
    paragrafo4: 'The Brazilian Classification System for terrestrial habitats used in the database was developed by the Brazilian Institute for Geography and Statistics (IBGE, 2012). The terms used for marine and freshwater environments were defined with experts in the respective fields. The controlled vocabulay was adopted from the Global Invasive Species Database.',
    paragrafo5_1: 'The main taxonomic references used are the Missouri Botanical Gardens ',
    paragrafo5_2: 'The Plant List',
    paragrafo5_3: ', and the United States Integrated Taxonomic Information System - ITIS ',
    paragrafo5_4: 'Other more specific databases are commonly used as well to verify and complement data, such as the World Register of Marine Species',
    paragrafo5_5: 'as well as the Global Invasive Species Database ',
    paragrafo5_6: 'and the CABI Invasise Species Compendium',
    paragrafo6: 'Species are classified in the following categories for every occurrence record:',
    marcador4: '– when a species is restrained to human use, such as lab experiments, or under cultivation or breeding, not being present in natural areas.',
    marcador5: '– when a species is found in a natural area, but has been planted, or was recently introduced, and no evidence of invasion or spread is available. ',
    marcador6: '– when a species is found in natural areas and a population has formed locally, and there is no evidence of dispersal to other areas.',
    marcador7: '– when the species occurs in natural areas, has started to reproduce and spread beyond the point of introduction.',
    paragrafo7: 'A species may therefore be considered invasive in one location, while it is casually present in another, or simply contained in a third place.',
    paragrafo8: 'Data have been collected for the country since 2003 with suppport from hundreds of people, listed in the database as Collaborators. These data providers send articles, reports, or field data with occurrences of species that are then added to the database.',
    paragrafo8_en: 'All the information on species occurrences are always connected to the source, as the name of the data provider is included with each record as well as references of published materials. Other data are referenced in the descriptive fields. In case of doubt or need for more information on a point of occurrence, users can contact the person who sent or published the data. The link between data and source is meant to ensure that every contribution is properly acknowledged and cited when used by others.',
    paragrafo9_1: 'If you have data on invasive alien species to contribute to this database, please use the spreadsheet available from our website and send it to ',
    paragrafo9_2: ' or contact us.',
    paragrafo10: 'For other issues or questions, please contact us as well! ',
    paragrafo11: 'The criteria for adding invasive alien species to the database are: (a) the species is present in Brazil and at least one or a few reliable records of occurrence are available; (b) the species has a history of invasion in Brazil or elsewhere, preferably in climatic conditions that would favor establishment in the country. There may be exceptions to species that have not been found invading in Brazil, but are considered of high risk to biodiversity, have been introduced and are so far contained (under cultivation or breeding, in captivity, in aquaria, laboratories, etc.), or to species present in neighboring countries with which Brazil maintains relevant commercial relations, which might facilitate introductions. No invasive species is added without one or more validated records of occurrence.',
    paragrafo12: 'The database covers invasive alien species in all biological groups with potential of impact on biological diversity and/or on natural areas. While the same species may generate negative impacts on the economy, on social and cultural values, or on human or animal health, the focus of this database is on species that impact natural ecosystems and biological diversity.',
    paragrafo13: 'Once a species is included in the database, complementary information is appended. New data is included as available from published materials, and new occurrences are added from collaborators, field observations by practitioners and protected area managers or rangers, and technical and scientific publications. The addition of geographic coordinates is compulsory for all occurrences.',
    paragrafo14: 'The data is continually reviewed and upadted from publications or communication with collaborators. When species in the database are questioned as new data are available, for example on the native range including Brazil, expert advice is sought by the Scientific Committee in order to decide whether the species in question should be removed or not. A few species that were included from the first national survey conducted in Brazil were later excluded for lack of evidence on invasion or because data were insufficient to comply with the inclusion criteria established. This may happen, for example, when a non-native species is found in a natural area, but there is no evidence of invasion over time, or data are insufficient to confirm invasion potential in the country or in other places in the world; or still, when a species native range is uncertain, and DNA analyses show that it is a native species in the region. In all these cases, the data is stored in a standby list to avoid any losses, and the species is no longer shown in the database.',
    paragrafo15: 'The validation of data included in the database by data providers / collaborators is conducted by verifying the reliability of the species identification and of places of occurrence.',
    paragrafo16: 'Taxonomic validation',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert on the biological group to which the species belongs, or have equivalent experience?',
    marcador9: 'Is the occurrence documented in a photograph through which it is possible to identify of the organism with high certainty?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a specimen deposited in a herbarium or museum?',
    marcador12: ' Does the occurrence correspond to a species that was already registered for the country?',
    marcador13: 'Is the taxonomy to which the group belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 leads directly to the recommendation to consider the data as validated. In case of answering negatively to these four questions, the validation will be obtained only with positive answers to the last three (5, 6 and 7).',
    paragrafo18: 'Geographic validation',
    marcador15: 'Does the data include geographic coordinates? Coordinates taken directly at the observation site as well as coordinates that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is there a description of the site that facilitates its location in the field?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'The species is already cited for the country and, in that case, is the locality included within its known range of distribution or in a site that is compatible with the sites of known presence and with its dispersal capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the location indicated, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are validated if:',
    marcador21: 'the answer to questions 1, 2 and/or 3, and 4 is positive;  ',
    marcador22: 'a positive or negative answer is assigned to question 1, a negative answer is given to questions 2 and 3, but questions 5 and 6 have positive answers. ',
    paragrafo20: 'The location will be considered not validated if: ',
    marcador23: 'the answer is positive to question 1 and questions 2 and/or 3, but negative to question 4; ',
    marcador24: 'negative or positive answer to question 1 and positive to one or none of questions 5 and 6.',
    paragrafo21: 'Finally, the data are considered validated if the validation analysis of the two criteria (taxonomy and location) is passed.',
    paragrafo22: 'With the permanent deletion of your account, all your data will be removed, and it will be necessary to create a new account if you wish to register again in the app.',
    paragrafo23: "By choosing the 'Delete account' option, all your account and profile data will be deleted, and it will not be possible to recover your account.",
    paragrafo24: 'The account may take a few hours to be completely removed.',
    marcador25: 'Access your account and open the settings tab;',
    marcador26: "Click on the 'Delete account' button;",
    marcador27: "A warning will appear asking if you really want to delete the account, click 'Yes';",
    paragrafo25: 'Done, your account deletion has been requested.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Scientific committee',
    paragrafo1: 'The National Invasive Alien Species Database is supported by network of collaborators who help validate information, share new publications and data. The network includes experts in environmental sciences and taxonomy, field practitioners and managers whose knowledge is unvaluable for the existence of the database.',
    paragrafo2: 'As the database was renewed in 2021, the contribution by experts began to be formally acknowledged. A few experts were invited to form a Scientific Committee for the database. The role of the Scientific Committee is to provide support in data validation, in updating information, and helping to build and maintain the network of collaborators, which should lead to an increase in the amount and quality of data received for inclusion in the database.',
    paragrafo3: 'Members of the Scientific Committee Comitê Científico are appointed for a period of three years, which may be renewed, or a member replaced. The first Scientific Committee was formed in the process of redesign of the database in 2021, and is composed by:',
    marcador1: 'Dr. Sílvia R. Ziller, Forester, Founder and Executiva Director of The Horus Institute for Environmental Conservation and Development, who fuctions as Database Administrator;',
    marcador2: 'Dr. Michele de Sá Dechoum, Biologist, Professor at the Federal University of Santa Catarina and collaborator of the Horus Institute since 2007,who also fuctions as Database Administrator;',
    marcador3: 'Dr. Rafael Dudeque Zenni, Forester, Professor at the Lavras Federal University in Minas Gerais, collaborator of the database since its inception in 2004;',
    marcador4: 'Dr. Sergio Zalba, Biologist, Professor at the Universidad Nacional del Sur in Bahía Blanca, Argentina, and Manager of the Argentina National Invasive Alien Species Database;',
    marcador4b: 'Dr. Rosana M. Rocha, Professor, Zoology Department, Federal University of Paraná, Brazil;',
    marcador4c: 'Dr. Mário Luís Orsi, Animal and Plant Biology Department, LEPIB, Londrina State University, Paraná, Brazil.',
    paragrafo4: 'The role of the Scientific Committee',
    marcador5: 'Make decisions on the inclusion of new species in the database;',
    marcador6: 'make decisions on the exclusion of species for lack of data consistency or evidence of invasion;',
    marcador7: 'support data validation;',
    marcador8: 'support on data reviews and for data searches;',
    marcador9: 'support to locate and consult with experts for data validation and to improve database content;',
    marcador10: 'make recommendations for improvement of the database;',
    marcador11: 'continuously increase and improve database content.'
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'This section of the database contains information on invasive alien species. Data have been collected since 2004 in Brazil, when the Brazil Ministry of Environment conducted the first national survey on invasive alien species. Information is received from data providers, including experts on biological groups who also help validate data, and collated from scientific and technical publications.',
    paragrafo2_1: 'If you have occurrence data for invasive alien species in Brazil, ',
    paragrafo2_2: 'please contribute to update this database',
    verTutorial: 'Open tutorial',
    paragrafo3: 'To cite data from this database, please use the following format:',
    paragrafo4_1_new: 'Instituto Hórus 2024. Base de Dados Nacional de Espécies Exóticas Invasoras. Disponível em https://bd.institutohorus.org.br/. Acesso em XX/XX/XXXX.',
    paragrafo4_1: 'Brazil National Invasive Alien Species Database. Instituto Hórus de Desenvolvimento e Conservação Ambiental (The Horus Institute for Environmental Conservation and Development), Florianópolis – SC.',
    paragrafo4_2: ' Accessed on __/__/____ (date)',
    tutorialTitulo1: 'Please cite our database',
    tutorialDescricao1: 'Please use this citation format in publications.',
    tutorialTitulo2: 'Use the filters',
    tutorialDescricao2: 'You’ll find some options to filter contents here.',
    tutorialTitulo3: 'Use the filters: select data',
    tutorialDescricao3: 'Choose filters and combine data by place, biological group, use, taxonomy, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive alien species in the database or resulting from your search using combined filters.',
    tutorialDescricao5: 'Here you can select the number of records you wish to view per page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or search results using filters. Click on the name of the species to see the specific data set.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter data and choose the format to export.',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture when possible!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, type of habitats it invades, and economic uses.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived, why it was introduced, and how it spreads to other areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Classification of environmental impacts according to the IUCN EICAT protocol.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention and early detection measures, mechanical, chemical, biological control.',
    tutorialTitulo12: 'Risk analysis',
    tutorialDescricao12: 'Risk analysis results for the species selected.',
    tutorialTitulo13: 'Places of occurrence',
    tutorialDescricao13: 'Location, ecosystems, protected areas, states in the country where the species occurs, and stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications on the species.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects on the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborators',
    paragrafo1: 'Collaborators are data providers, or the people who have contributed information to the database: scientific or technical publications, data on museum or herbarium species or data collected in the field. Some data providers help validate information and identify species. ',
    paragrafo2: 'Contributions of places of occurrence for species in the database are highly relevant for us to update distribution data and detect new areas of invasion. This in turn helps managers respond with management actions and allows us to share knowledge on species locations.',
    paragrafo3: 'To cite data from this database, please use the following format:',
    paragrafo4_1: 'Brazil National Invasive Alien Species Database. Instituto Hórus de Desenvolvimento e Conservação Ambiental (The Horus Institute for Environmental Conservation and Development), Florianópolis – SC.',
    paragrafo4_2: ' Accessed on __/__/____ (date)'
  },
  especiesMarinhasInstitucional: {
    titulo: 'Marine species',
    paragrafo1: 'The majority of marine species are introduced unintentionally through commercial shipping and movement of marine structures such as oil and gas platforms. Some species are transported as larvae in ballast water, others adhere to ship hulls and other structures, which is called biofouling. Most invasive marine species are invertebrates (shrimps, crabs, mussels, sea worms, etc.), some are fishes, others are algae. ',
    paragrafo2: 'Because the natural distribution range of many marine species is not well-known, it is sometimes difficult to categorize them as invasive, so most inferences on invasion are made based on the fact that the species did not exist in an area before.  ',
    paragrafo3: 'Learn more',
    paragrafo4: 'Tubastrea coccinea - sun coral',
    paragrafo4b: 'Photo by: Bruna Folchini Gregoletto',
    paragrafo5: 'Pterois volitans - lion fish',
    paragrafo5b: 'Photo by The Horus Institute',
    paragrafo6: 'Ophiotela mirabilis - star fish',
    paragrafo7: 'Charybdis hellerii - swimming crab'
  },
  peixesAquarioInstitucional: {
    titulo: 'Aquarium fishes',
    paragrafo1: 'Aquarium fishes are currently the most numerous pets in the world. Brazil is a large provider of aquarium fishes due to the high species richness in the Amazon and Pantanal. Still, most fishes are non-native wherever they are sold. Such fishes must never be released in lakes, riverrs or the ocean, as they may prey on native species, change natural habitats or transmit diseases or parasites.',
    paragrafo2: 'Besides, they may become invasive, as in the case of lion fish, which escaped na aquarium in Florida, USA, and is now invasive throughout the Caribbean. ',
    paragrafo3: 'It might reach the coast of Brazil, where it ir a high risk for coral reefs in Abrolhos and Fernando de Noronha. ',
    paragrafo4: 'Learn more:',
    marcador1: 'Non-native ornamental fishes threaten biodiversity',
    marcador2: 'Non-native invaders',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - cichlid fish',
    paragrafo5b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenile)',
    paragrafo6b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae cichlid fish',
    paragrafo7b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - serpae tetra fish',
    paragrafo8b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - fat oscar',
    paragrafo9b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - zebra danio',
    paragrafo10b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - lion fish',
    paragrafo11b: 'Photo by: The Horus Institute'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Aquaculture fishes',
    paragrafo1: 'Aquaculture is the main pathway of introduction of freshwater fishes in Brazil. Many species are quite aggressive and have caused the extinction of native species in seveal countries, such as the Nile tilápia and the African catfish. Pangasius Is a fish native to Asia now being cultivated in Brazil in spite of not having been authorized by the federal environmental agency. It is highly invasive, and survives in low quality waters. Learn about fish products before you buy them to avoid contributing to environmental impacts. ',
    paragrafo2: 'The problem is that breeding facilities such as artificial ponds and net cages are not safe enough to avoid escapes, or fishes are releaseed on purpose by breeders. They may prey on native species, change the natural aquatic environment, or transmit diseases or parasites. ',
    paragrafo3: 'Learn more.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi - peacock bass',
    paragrafo5: 'Coptodon rendalli - tilapia',
    paragrafo6: 'Cyprinus carpio - common carp',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Photo by: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus - Nile tilapia'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Sport fishing',
    paragrafo1: 'Sport fishing is a leisure activity that has led to the introduction of many non-native species which are often varacious predators. Although the introduction of non-native species and the translocation of species between watersheds is prohibited in Brazil, (Portaria IBAMA 145-N/1998), many fishes have been taken from their watersheds of origin to others where they are not native, creating impacts on native species by predation, competition, and transmission of diseases and parasites, apart from ecological imbalance.',
    paragrafo2: 'The introduction of invasive species used as live bait is associated with sport fishing, as they may escape from fish hooks or even be discarded in natural water bodies. Fishes and other species used as live bait should only be used when native to the watershed where you go fishing to avoid introducing alien species and their impacts on native species.',
    paragrafo3: 'Never release non-native species in nature!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Photo by: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - peacock bass',
    paragrafo5b: 'Photo by: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - peacock bass',
    paragrafo6b: 'Photo by: LEPIB - UEL PR',
    paragrafo7: 'Learn more'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'The word ',
    pet: 'pet',
    paragrafo1_2: ' refers to animals maintained for company, from aquarium fishes to dogs and cats. Some animals listed here, especially cats and dogs, are only considered a problem when present in natural areas where they can harm native animals.',
    paragrafo2: 'Do not allow your pet to wander in natural areas! ',
    paragrafo3: 'Trachemys scripta - red-eared slider',
    paragrafo3b: 'Photo by: Marcos Tortato',
    paragrafo4: 'Felis catus - domestic cat',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Canis familiaris - domestic dog',
    paragrafo5b: 'Photo by: Marcos Tortato',
    paragrafo6: 'Trachemys dorbigni - slider',
    paragrafo6b: 'Photo by: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Forage plants',
    paragrafo1: 'Many plants have been introduced for use as forage, i.e., to feed domestic animals such as cattle, goats, horses or mules. These plants are mainly grasses, among which braquiarias are aggressive invasives in natural areas, degraded areas, and even wetlands and small streams. Small trees such as leucena, and trees such as mesquite are also used as forage, the pods fed to goats.',
    paragrafo2: 'These species were widely disseminated in the tropical world and cause environmental impacts by changing natural cycles such as fire, reducing water availability, and displacing native species by forming dense stands where nothing else can grow.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Urochloa decumbens - brachiaria',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Prosopis juliflora - mesquite',
    paragrafo5b: 'Photo by: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - molassess grass',
    paragrafo6b: 'Photo by: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - jaragua grass',
    paragrafo7b: 'Photo by: The Horus Institute'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Ornamental plants make up for more than 50% of all invasive alien plants in Brazil. The choice of plants for gardens and urban landscaping can contribute for the conservation of nature, which includes water provisioning, climate stability and many other benefits of nature to people.',
    paragrafo2_1: 'Grow plants that are native in your area',
    paragrafo2_2: 'Get to know, avoid and replace invasive alien plants in your garden.',
    paragrafo3: 'Find native alternatives for your area in the ',
    arquiflora: 'Arquiflora website',
    paragrafo4: 'Tecoma stans - yellow bells',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Furcraea foetida - Mauritius hemp',
    paragrafo5b: 'Photo by: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - dumb cane',
    paragrafo6b: 'Photo by: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - asparagus fern',
    paragrafo7b: 'Photo by: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - tropical almond tree',
    paragrafo8b: 'Photo by: The Horus Institute'
  },
  projetosInstitucional: {
    titulo: 'Projects',
    paragrafo1: 'In this section you\'ll find references on research and management projects as well as on other issues related to invasive alien species. We hope to facilitate the integration of people working on the same species or on similar issues or areas, as well as provide data on work taking place in different regions. ',
    paragrafo2_1: 'If you are or have been involved in a project on invasive alien species in Brazil, ',
    paragrafo2_2: 'please contribute data so we can keep the database up to date',
    paragrafo2_3: '. We hope to benefit your project by disseminating information.',
    paragrafo3: 'To cite data from this database, please use the following format:',
    paragrafo4_1: 'Brazil Invasive Alien Species Database. Instituto Hórus de Desenvolvimento e Conservação Ambiental (The Horus Institute for Environmental Conservation and Development), Florianópolis – SC.',
    paragrafo4_2: 'Accessed on __/__/____ (date)'
  },
  referenciasInstitucional: {
    titulo: 'References',
    paragrafo1: 'The references listed here were used to fill out the data fields on invasive alien species, and include data on the biology and ecology of species, introduction and spread, management, and places of occurrence. This section displays all the references used for all the species in the database, while the references inside the Species section are listed for the species selected.',
    paragrafo2: 'If you have publications on invasive alien species that are not listed here, please let us know and contribute to maintain the database up to date.',
    paragrafo3: 'Contribute with data',
    paragrafo4: 'To cite data from this database, please use the following format:',
    paragrafo5_1: 'Brazil Invasive Alien Species Database. Instituto Hórus de Desenvolvimento e Conservação Ambiental (The Horus Institute for Environmental Conservation and Development), Florianópolis – SC.',
    paragrafo5_2: 'Accessed on __/__/____ (date)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Forestry',
    paragrafo1: 'Several non-native species were introduced in Brazil after 1950 by government initiatives to develop a forestry industy for pulp, paper, wood and other subproducts. Some of these species became invasive, especially pines which have been distributed to many ecosystems. These species require continuous control to prevent them from escaping plantations and causing environmental damage. Forest certification requires companies to control invasions, and such trees have to be eliminated from natural areas, roadsides and railways, riparian forests, and other habitat types where they are not cultivated.',
    paragrafo2: 'The genus Pinus is considered the most invasive genus of tree in the southern hemisphere! Pines displace native vegetation in open ecosystems and use up more water than native plants.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Pinus sp. - pine',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Acacia mearnsii - black wattle',
    paragrafo5b: 'Photo by: The Horus Institute',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Photo by: The Horus Institute'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  }
}
