<template>
  <div class="container-fluid p-0">
    <PageTitle :title="pageTitle" :titleSave="$t('label.salvar')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
              <div class="align-self-center">
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-3 ">
                      <label for="protected-area" class="form-label">{{ $t('label.descricao') }}</label>
                      <input class="form-control" type="text" v-model="form.name" :class="{ 'is-invalid': $v.form.name.$dirty && $v.form.name.$error }">
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                       </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="usuarios-ativo" class="form-label">{{ $t('label.ativo') }}</label>
                      <div class="mt-0">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" :value="true" v-model="form.active">
                            <label class="form-check-label" >{{ $t('label.sim') }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" :value="false" v-model="form.active">
                            <label class="form-check-label" >{{ $t('label.nao') }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'GrupoEmailsForm',
  components: {
    PageTitle
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.grupoDeEmails')} > Adicionar`,
      nameEdit: '',
      action: this.$actionNew,
      form: { name: '', active: true }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  methods: {
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/SysEmailGroup/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.name}`
        })
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      if (this.action === this.$actionEdit) {
        axios.put('/SysEmailGroup', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      } else {
        axios.post('/SysEmailGroup', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/admin/grupo-emails')
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/SysEmailGroup/${this.form.name_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    }
  },
  created: function () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('label.grupoDeEmails')} > Adicionar`
      this.getById(this.$route.params.id)
    }
  }
}
</script>
