<template>
  <div class="container-fluid p-0">
    <PageTitle :title="pageTitle" :titleSave="$t('label.salvar')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
              <div class="align-self-center">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3 ">
                      <label for="protected-area" class="form-label">{{ $t('label.descricao') }}</label>
                      <input class="form-control" type="text" v-model="form.protected_area" :class="{ 'is-invalid': $v.form.protected_area.$dirty && $v.form.protected_area.$error }">
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                       </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3 ">
                      <label for="email_group" class="form-label">{{ $t('label.grupoDeEmails') }}</label>
                      <v-select multiple :appendToBody="true" :filterable="false" @search="getEmailGroup" :options="groupList" v-model="relProtectedAreaEmailGroupSelected" label="name" index="email_group_id"
                          :reduce="r => r.email_group_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'AreaProtegidaForm',
  components: {
    PageTitle
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.areaProtegida')} > Adicionar`,
      nameEdit: '',
      groupList: [],
      relProtectedAreaEmailGroupSelected: [],
      action: this.$actionNew,
      form: {}
    }
  },
  validations: {
    form: {
      protected_area: { required }
    }
  },
  methods: {
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/VocProtectedArea/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.protected_area}`
          this.getRelProtectedAreaEmailGroupSelected()
        })
    },
    getRelProtectedAreaEmailGroupSelected: function () {
      this.relProtectedAreaEmailGroupSelected = []
      this.form.relProtectedAreaEmailGroup.forEach(e => {
        if (!this.relProtectedAreaEmailGroupSelected.includes(e.email_group_id)) {
          this.relProtectedAreaEmailGroupSelected.push(Number(e.email_group_id))
        }
      })
      this.getEmailGroup()
    },
    getEmailGroup: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.name = search
        params.email_group_id_array = this.relProtectedAreaEmailGroupSelected.join(',')
        loading(true)
        axios.get('/SysEmailGroup/getallcombo', { params })
          .then(response => {
            this.groupList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.email_group_id_array = this.relProtectedAreaEmailGroupSelected.join(',')
        axios.get('/SysEmailGroup/getallcombo', { params })
          .then(response => {
            this.groupList = response.data.items
          })
      }
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      console.log(this.relProtectedAreaEmailGroupSelected)
      this.form.relProtectedAreaEmailGroup = []
      this.relProtectedAreaEmailGroupSelected.forEach(el => {
        this.form.relProtectedAreaEmailGroup.push({ email_group_id: el, protected_area_id: this.form.protected_area_id })
      })
      console.log(this.form.relProtectedAreaEmailGroup)
      if (this.action === this.$actionEdit) {
        axios.put('/VocProtectedArea', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      } else {
        axios.post('/VocProtectedArea', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/admin/area-protegida')
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/VocProtectedArea/${this.form.protected_area_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    }
  },
  created: function () {
    this.getEmailGroup()
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('label.areaProtegida')} > Adicionar`
      this.getById(this.$route.params.id)
    }
  }
}
</script>
