<template>
<div class="tab-pane fade " :class="{ 'show active': aba===1 }" id="taxonomia" role="tabpanel" aria-labelledby="taxonomia-tab">
  <div class="container-fluid mt-3">
    <form id="add_taxonomia">
        <div class="row">
          <div class="col-md-6">
              <div class="mb-3" v-if="!byInstitucional">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1" v-model="form.species_type" :disabled="!user || user.perfilUser !== 'administrator'">
                    <label class="form-check-label" for="inlineRadio1">{{ $t('label.invasoras') }}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2" v-model="form.species_type" :disabled="!user || user.perfilUser !== 'administrator'">
                    <label class="form-check-label" for="inlineRadio2">{{ $t('label.contidasBrasil') }}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" :value="3" v-model="form.species_type" :disabled="!user || user.perfilUser !== 'administrator'">
                    <label class="form-check-label" for="inlineRadio3">{{ $t('label.ausentesBrasil') }}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" :value="4" v-model="form.species_type" :disabled="!user || user.perfilUser !== 'administrator'">
                    <label class="form-check-label" for="inlineRadio4">{{ $t('label.deficiencia') }}</label>
                </div>
              </div>
              <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisScientificName }">
                <label for="taxonomia-nome-cientifico" class="form-label">{{ $t('label.nomeCientifico') }}</label>
                <button v-if="isPendingAnalysisScientificName" class="btn" type="button" @click="$emit('analysis', $t('label.nomeCientifico'), 'scientific_name')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                <input type="text" class="form-control"  id="taxonomia-nome-cientifico" v-model="form.scientific_name"
                  :class="{ 'is-invalid': $v.form.scientific_name.$dirty && $v.form.scientific_name.$error }" :disabled="byInstitucional">
                <div class="invalid-feedback">
                  {{ $t('message.requiredField') }}
                </div>
              </div>
              <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisDataCommonNames }">
                <label for="taxonomia-nome-comum" class="form-label">{{ $t('label.nomeComum') }}</label>
                <button v-if="isPendingAnalysisDataCommonNames" class="btn" type="button" @click="$emit('analysis', $t('label.nomeComum'), 'dataCommonNames')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                <div class="box-dashboard pt-2 pb-2 pr-1 pl-2 box_nome_comum">
                    <div class="box_scroll_y box_scroll_x" :style="showImage ? 'height: 475px;' : 'height: 300px;'">
                      <template v-for="(item, index) in form.dataCommonNames">
                        <EditDataCommonNames :key="index" :commonName="item" v-if="viewEditCommonName===`edit-${item.common_name_id}`"
                          @cancelEditCommonName="cancelEditCommonName" @salveEditCommonName="salveEditCommonName"/>
                        <span :key="index" class="edit-label" v-else>
                          <p class="d-flex justify-content-start">{{`${item.common_name}`}}</p>
                          <ul class="acoes d-flex justify-content-end" v-if="!byInstitucional">
                            <li><a @click="viewEditCommonName=`edit-${item.common_name_id}`"><span class="editar"></span></a></li>
                            <li><a @click="removeCommonName(item)"><span class="apagar"></span></a></li>
                          </ul>
                        </span>
                      </template>
                      <template v-if="!byInstitucional">
                        <AddDataCommonNames v-if="viewAddCommonName" @cancelAddCommonName="cancelAddCommonName" @salveAddCommonName="salveAddCommonName"/>
                        <span v-else class="add-btn-label" @click="viewAddCommonName=!viewAddCommonName"></span>
                      </template>
                    </div>
                </div>
              </div>
          </div>
          <div class="col-md-6 border-start">
              <div class="mb-3">
                <label for="taxonomia-foto" class="form-label">{{ $t('label.foto') }}</label>
                <div v-if="showImage" class="bg-dark container-fluid">
                  <img :src="url" alt="Foto" width="200" height="200" class="img-fluid mx-auto d-block">
                </div>
                <input class="form-control" type="file" accept="image/*" id="taxonomia-foto" @change="onFileChange" v-if="!byInstitucional">
              </div>
              <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisImageCredit }">
                <label for="taxonomia-credito" class="form-label">{{ $t('label.credito') }}</label>
                <button v-if="isPendingAnalysisImageCredit" class="btn" type="button" @click="$emit('analysis', $t('label.credito'), 'image_credit')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                <input type="text" class="form-control" id="taxonomia-credito" v-model="form.image_credit" :disabled="byInstitucional">
              </div>
              <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisDataSynonyms }">
                <label for="taxonomia-sinonimos" class="form-label">{{ $t('label.sinonimos') }}</label>
                <button v-if="isPendingAnalysisDataSynonyms" class="btn" type="button" @click="$emit('analysis', $t('label.sinonimos'), 'dataSynonyms')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                <div class="box-dashboard pt-2 pb-2 pr-1 pl-2 box_sinonimos">
                    <div class="box_scroll_y box_scroll_x" style="height: 297px;">
                      <template v-for="(item, index) in form.dataSynonyms">
                        <EditDataSynonyms :key="index" :synonymous="item" v-if="viewEditSynonymous===`edit-${item.synonymous_id}-${index}`"
                          @cancelEditSynonymous="cancelEditSynonymous" @salveEditSynonymous="salveEditSynonymous"/>
                        <span :key="index" class="edit-label" v-else>
                          <p class="d-flex justify-content-start">{{item.synonymous}}</p>
                          <ul class="acoes d-flex justify-content-end" v-if="!byInstitucional">
                            <li><a @click="viewEditSynonymous=`edit-${item.synonymous_id}-${index}`"><span class="editar"></span></a></li>
                            <li><a @click="removeSynonymous(item)"><span class="apagar"></span></a></li>
                          </ul>
                        </span>
                      </template >
                      <template v-if="!byInstitucional">
                        <AddDataSynonyms v-if="viewAddSynonymous" @cancelAddSynonymous="cancelAddSynonymous" @salveAddSynonymous="salveAddSynonymous"/>
                        <span v-else class="add-btn-label" @click="viewAddSynonymous=!viewAddSynonymous"></span>
                      </template >
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12  border-top p-2">
              <div class="row">
                <div class="col mb-3" :class="{ 'pending_analysis': isPendingAnalysisAuthor }">
                    <label for="taxonomia-autor" class="form-label">{{ $t('label.autor') }}</label>
                    <button v-if="isPendingAnalysisAuthor" class="btn" type="button" @click="$emit('analysis', $t('label.autor'), 'author')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" class="form-control" id="taxonomia-autor" v-model="form.author" aria-label="Autor" :disabled="byInstitucional">
                </div>
                <div class="col mb-3" :class="{ 'pending_analysis': isPendingAnalysisSubspecies }">
                    <label for="taxonomia-subespecie" class="form-label">{{ $t('label.subespecie') }}</label>
                    <button v-if="isPendingAnalysisSubspecies" class="btn" type="button" @click="$emit('analysis', $t('label.subespecie'), 'subspecies')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" class="form-control" id="taxonomia-subespecie" v-model="form.subspecies" aria-label="Subespécie" :disabled="byInstitucional">
                </div>
                <div class="col mb-3" :class="{ 'pending_analysis': isPendingAnalysisSubAuthor }">
                    <label for="taxonomia-autor-subespecie" class="form-label">{{ $t('label.autorSubespecie') }}</label>
                    <button v-if="isPendingAnalysisSubAuthor" class="btn" type="button" @click="$emit('analysis', $t('label.autorSubespecie'), 'sub_author')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" class="form-control" id="taxonomia-autor-subespecie" v-model="form.sub_author" aria-label="Autor subespécie" :disabled="byInstitucional">
                </div>
              </div>
              <div class="row">
                <div class="col mb-3" :class="{ 'pending_analysis': isPendingAnalysisGenusId }">
                  <label for="taxonomia-genero" class="form-label">{{ $t('label.genero') }}</label>
                  <button v-if="isPendingAnalysisGenusId" class="btn" type="button" @click="$emit('analysis', $t('label.genero'), 'genus_id', taxonomyGenusList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
                  <v-select :on-change="getTaxonomiaGenusById(form.genus_id)" :appendToBody="true" :options="taxonomyGenusList" v-model="form.genus_id" label="genus" index="genus_id"
                    :reduce="r => r.genus_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': $v.form.genus_id.$dirty && $v.form.genus_id.$error }" :disabled="byInstitucional">
                    <template v-slot:selected-option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    {{ $t('message.requiredField') }}
                  </div>
                </div>
                <div class="col mb-3">
                    <label for="taxonomia-familia" class="form-label">{{ $t('label.familia') }}</label>
                    <input type="text" class="form-control" id="taxonomia-familia" disabled="disabled"
                      v-model="form.taxonomyGenus.taxonomyFamily.family" aria-label="Família">
                </div>
                <div class="col mb-3">
                    <label for="taxonomia-ordem" class="form-label">{{ $t('label.ordem') }}</label>
                    <input type="text" class="form-control" id="taxonomia-ordem" disabled="disabled"
                      v-model="form.taxonomyGenus.taxonomyFamily.taxonomyOrder.oorder" aria-label="Ordem">
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                    <label for="taxonomia-classe" class="form-label">{{ $t('label.classe') }}</label>
                    <input type="text" class="form-control" id="taxonomia-classe" disabled="disabled"
                      v-model="form.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.class" aria-label="Classe">
                </div>
                <div class="col mb-3">
                    <label for="taxonomia-phylum" class="form-label">{{ $t('label.phylum') }}</label>
                    <input type="text" class="form-control" id="taxonomia-phylum" disabled="disabled"
                      v-model="form.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.phyllum" aria-label="Phylum">
                </div>
                <div class="col mb-3">
                    <label for="taxonomia-reino" class="form-label">{{ $t('label.reino') }}</label>
                    <input type="text" class="form-control" id="taxonomia-reino" disabled="disabled"
                      v-model="form.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.taxonomyKingdom.kingdom" aria-label="Reino">
                </div>
              </div>
          </div>
        </div>
    </form>
  </div>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import EditDataCommonNames from './EditDataCommonNames'
import AddDataCommonNames from './AddDataCommonNames'
import EditDataSynonyms from './EditDataSynonyms'
import AddDataSynonyms from './AddDataSynonyms'
import { mapState } from 'vuex'
export default {
  name: 'EspeciesTabTaxonomia',
  components: {
    EditDataCommonNames,
    AddDataCommonNames,
    EditDataSynonyms,
    AddDataSynonyms
  },
  props: {
    byInstitucional: Boolean,
    aba: Number,
    form: {},
    loadValidatorTaxonomia: Boolean,
    onlyLoadValidator: Boolean,
    startUpload: Boolean,
    pendingAnalysis: Boolean
  },
  data: function () {
    return {
      viewEditCommonName: '',
      viewAddCommonName: false,
      viewEditSynonymous: false,
      viewAddSynonymous: false,
      taxonomyGenusList: [],
      file: null,
      url: null,
      showImage: false,
      changeImage: false
    }
  },
  validations: {
    form: {
      scientific_name: { required },
      genus_id: { required }
    }
  },
  watch: {
    'loadValidatorTaxonomia' (newValue) {
      if (newValue) {
        this.$v.$touch()
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v)
      }
    },
    'startUpload' (newValue) {
      if (newValue) {
        this.upload()
      }
    },
    'form.image_name' (newValue) {
      if (newValue) {
        this.getImage()
      }
    }
  },
  computed: {
    ...mapState(['user']),
    pendingDataCommonNames: function () {
      let result = false
      if (this.form.dataCommonNames && this.form.log_update && this.form.log_update.DataCommonNames) {
        if (this.form.dataCommonNames.length !== this.form.log_update.DataCommonNames.length) {
          return true
        }
        this.form.log_update.DataCommonNames.forEach(e => {
          const list = this.form.dataCommonNames.filter(x => x.common_name === e.Common_name)
          if (list.length === 0) {
            result = true
          }
        })
      }
      return result
    },
    pendingDataSynonyms: function () {
      let result = false
      if (this.form.dataSynonyms && this.form.log_update && this.form.log_update.DataSynonyms) {
        if (this.form.dataSynonyms.length !== this.form.log_update.DataSynonyms.length) {
          return true
        }
        this.form.log_update.DataSynonyms.forEach(e => {
          const list = this.form.dataSynonyms.filter(x => x.synonymous === e.Synonymous)
          if (list.length === 0) {
            result = true
          }
        })
      }
      return result
    },
    isPendingAnalysis: function () {
      return !this.byInstitucional && this.pendingAnalysis && this.form.log_update
    },
    isPendingAnalysisScientificName: function () {
      const result = this.isPendingAnalysis && this.form.scientific_name !== this.form.log_update.Scientific_name
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisDataCommonNames: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingDataCommonNames
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisImageCredit: function () {
      const result = this.isPendingAnalysis && this.form.image_credit !== this.form.log_update.Image_credit
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisDataSynonyms: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingDataSynonyms
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisAuthor: function () {
      const result = this.isPendingAnalysis && this.form.author !== this.form.log_update.Author
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisSubspecies: function () {
      const result = this.isPendingAnalysis && this.form.subspecies !== this.form.log_update.Subspecies
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisSubAuthor: function () {
      const result = this.isPendingAnalysis && this.form.sub_author !== this.form.log_update.Sub_author
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    },
    isPendingAnalysisGenusId: function () {
      const result = this.isPendingAnalysis && this.form.genus_id !== this.form.log_update.Genus_id
      if (typeof result !== 'undefined' && result !== null) {
        this.$emit('especiesTabTaxonomiaPending', result)
      }
      return result
    }
  },
  methods: {
    getImage: function () {
      if (this.form.image_name) {
        axios.get(`/Upload/UploadImage/${this.form.species_id}/${this.form.image_name}`)
          .then(resp => {
            if (resp.data) {
              this.url = `data:image/*;base64, ${resp.data}`
              this.showImage = true
            }
          })
      }
    },
    cancelEditCommonName: function (value) {
      this.form.dataCommonNames.forEach(e => {
        if (e.common_name_id === value.common_name_id) {
          e.common_name = value.common_name
          e.language_id = value.language_id
        }
      })
      this.viewEditCommonName = ''
    },
    salveEditCommonName: function () {
      this.viewEditCommonName = ''
    },
    cancelAddCommonName: function () {
      this.viewAddCommonName = false
    },
    salveAddCommonName: function (value) {
      if (value.common_name_id === 0) {
        value.common_name_id = new Date().getMilliseconds()
      }
      this.form.dataCommonNames.push(value)
      this.viewAddCommonName = false
    },
    removeCommonName: function (item) {
      this.form.dataCommonNames = this.form.dataCommonNames.filter(function (el) {
        return el.common_name !== item.common_name
      })
      this.viewAddCommonName = false
    },
    salveEditSynonymous: function () {
      this.viewEditSynonymous = false
    },
    cancelEditSynonymous: function (value) {
      this.form.dataSynonyms.forEach(e => {
        if (e.synonymous_id === value.synonymous_id) {
          e.synonymous = value.synonymous
        }
      })
      this.viewEditSynonymous = false
    },
    removeSynonymous: function (item) {
      this.form.dataSynonyms = this.form.dataSynonyms.filter(function (el) {
        return el.synonymous_id !== item.synonymous_id
      })
      this.viewAddSynonymous = false
    },
    cancelAddSynonymous: function () {
      this.viewAddSynonymous = false
    },
    salveAddSynonymous: function (value) {
      if (value.synonymous_id === 0) {
        value.synonymous_id = new Date().getMilliseconds()
      }
      this.form.dataSynonyms.push(value)
      this.viewAddSynonymous = false
    },
    getTaxonomyGenus: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/TaxonomyGenus/getall', { params })
        .then(response => {
          this.taxonomyGenusList = response.data.items
        })
    },
    getTaxonomiaGenusById: function (id) {
      if (id !== this.form.taxonomyGenus.genus_id) {
        axios.get(`/TaxonomyGenus/${id}`)
          .then(response => {
            this.$emit('setTaxonomiaGenus', response.data)
          })
      }
    },
    onFileChange: function (e) {
      this.file = e.target.files[0]
      this.url = URL.createObjectURL(this.file)
      this.changeImage = true
      this.showImage = true
    },
    upload: function () {
      if (this.changeImage) {
        const formData = new FormData()
        formData.append('file', this.file)
        axios.post(`/upload/UploadImage/${this.form.species_id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(() => {
            this.$toasted.global.saved()
          })
      } else {
        this.$toasted.global.saved()
      }
    }
  },
  created () {
    this.getTaxonomyGenus()
    this.getImage()
  }
}
</script>
