<template>
  <div>
    <InstitucionalHeaderSingle :classProp="'header-single header-rede'" :title="`${$t('redeBaseDeDados.cabecalhoTitulo') }`"/>
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>{{ $t('redeBaseDeDados.paragrafo1') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2_1') }}</p>
          <ul>
            <li><a href="http://www.inbiar.uns.edu.ar" target="_blank">{{ $t('label.argentina') }}</a>: {{ $t('redeBaseDeDados.marcador1') }}</li>
            <li><a href="https://bd.institutohorus.org.br" target="_blank">{{ $t('label.brasil') }}</a>: {{ $t('redeBaseDeDados.marcador2') }}</li>
            <li><a href="https://invasoraschile.udec.cl/" target="_blank">{{ $t('label.chile') }}</a>: {{ $t('redeBaseDeDados.marcador3') }}</li>
            <li><a href="https://cr-invasoras.ucr.ac.cr" target="_blank">{{ $t('label.costarica') }}</a>: {{ $t('redeBaseDeDados.marcador4') }}</li>
            <li><a href="https://invasoras.biodiversidad.gob.ec" target="_blank">{{ $t('label.equador') }}</a>: {{ $t('redeBaseDeDados.marcador5') }}</li>
            <li><a href="https://hn-invasoras.ucr.ac.cr/" target="_blank">{{ $t('label.honduras') }}</a>: {{ $t('redeBaseDeDados.marcador5_1') }}</li>
            <li><a href="http://jamaicainvasives.licj.org.jm" target="_blank">{{ $t('label.jamaica') }}</a>: {{ $t('redeBaseDeDados.marcador5_2') }}</li>
            <li><a href="https://py-invasoras.ucr.ac.cr/" target="_blank">{{ $t('label.paraguai') }}</a>: {{ $t('redeBaseDeDados.marcador5_3') }}</li>
            <li><a href="https://sieei.udelar.edu.uy" target="_blank">{{ $t('label.uruguai') }}</a>: {{ $t('redeBaseDeDados.marcador5_4') }}</li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo3') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo4') }}</p>

          <p>{{ $t('redeBaseDeDados.paragrafo5') }}</p>
          <ul>
            <li>{{ $t('redeBaseDeDados.marcador6') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador7') }} <a href="http://portaleei.udelar.edu.uy" target="_blank">{{ $t('redeBaseDeDados.marcador7_1') }}</a> {{ $t('redeBaseDeDados.marcador7_2') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador8') }} <a href="http://www.vibbra.com.br" target="_blank">{{ $t('redeBaseDeDados.marcador8_1') }}</a> {{ $t('redeBaseDeDados.marcador8_2') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador9') }} </li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo6') }}</p>

          <h3 class="h5 mt-5 mb-2 laranja">{{ $t('redeBaseDeDados.paragrafo7') }}</h3>
          <div class="row">
            <div class="col-md-4">
              <p class="my-3">{{ $t('redeBaseDeDados.paragrafo8') }}</p>
              <ul class="rede_apoio">
                <li><img src="../../../assets/institucional/img/logo/apoio-biobridge.png" alt="Bio Bridge"></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-green-web.png" alt="CBD Green Web"></li>
              </ul>
            </div>
            <div class="col-md-8">
              <p class="my-3">{{ $t('redeBaseDeDados.paragrafo9') }}</p>
              <ul class="rede_apoio">
                <li><img src="../../../assets/institucional/img/logo/apoio_bco_mundial.png" alt="Banco Mundial"></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-gef.png" alt="GEF"></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-usgs.png" alt="USGS"></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-nbii.png" alt="Nbii"></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-iabin.png" alt="Iabin"></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'RedeBaseDeDados',
  components: { InstitucionalHeaderSingle }
}
</script>
