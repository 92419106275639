<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('politicaDeDados.cabecalhoTitulo') }`" :classProp="'header-single header-politica'"/>
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5 politica-de-dados">

          <ul class="menu-politica " id="menu-politica">
            <li class="list-group-item"><a href="#conceitos" >{{ $t('politicaDeDados.titulo') }}</a></li>
            <li class="list-group-item"><a href="#criterios" >{{ $t('politicaDeDados.criterio') }}</a></li>
            <li class="list-group-item"><a href="#avaliacao" >{{ $t('politicaDeDados.avaliacao') }}</a></li>
            <li class="list-group-item"><a href="#validacao" >{{ $t('politicaDeDados.validacao') }}</a></li>
            <li class="list-group-item"><a href="#exclusao" >{{ $t('politicaDeDados.exclusao') }}</a></li>
          </ul>

          <h2 class="laranja mb-5 pt-5" id="conceitos">{{ $t('politicaDeDados.titulo') }}</h2>

          <p>{{ $t('politicaDeDados.paragrafo1') }}</p>

          <ol>
            <li>{{ $t('politicaDeDados.marcador1') }} </li>
            <li>{{ $t('politicaDeDados.marcador2') }}</li>
            <li>{{ $t('politicaDeDados.marcador3') }}</li>
          </ol>

          <p>{{ $t('politicaDeDados.paragrafo2') }} </p>
          <p>{{ $t('politicaDeDados.paragrafo3') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo4') }} (<a href="https://www.issg.org" target="_blank">www.issg.org</a>). </p>
          <p>{{ $t('politicaDeDados.paragrafo5_1') }}(<a href="https://www.tropicos.org" target="_blank">www.tropicos.org</a>), {{ $t('politicaDeDados.paragrafo5_2') }}(<a href="https://www.theplantlist.org" target="_blank">www.theplantlist.org</a>) {{ $t('politicaDeDados.paragrafo5_3') }} (<a href="https://www.itis.gov" target="_blank">www.itis.gov</a>). {{ $t('politicaDeDados.paragrafo5_4') }} (<a href="https://www.marinespecies.org" target="_blank">www.marinespecies.org</a>), Fishbase (<a href="https://www.fishbase.org" target="_blank">www.fishbase.org</a>), Algaebase (<a href="https://www.algaebase.org" target="_blank">www.algaebase.org</a>), {{ $t('politicaDeDados.paragrafo5_5') }} (<a href="https://www.issg.org/database" target="_blank">www.issg.org/database</a>) {{ $t('politicaDeDados.paragrafo5_6') }} (<a href="https://www.cabi.org/isc" target="_blank">www.cabi.org/isc</a>).</p>
          <p>{{ $t('politicaDeDados.paragrafo6') }}</p>
          <ol>
            <li><strong>{{ $t('label.contida') }}</strong> {{ $t('politicaDeDados.marcador4') }}</li>
            <li><strong>{{ $t('label.casual') }}</strong> {{ $t('politicaDeDados.marcador5') }}</li>
            <li><strong>{{ $t('label.estabelecida') }}</strong> {{ $t('politicaDeDados.marcador6') }}</li>
            <li><strong>{{ $t('label.invasora') }}</strong> {{ $t('politicaDeDados.marcador7') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo7') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo8') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo8_en') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo9_1') }}<a href="mailto:invasoras@institutohorus.org.br" target="_blank">invasoras@institutohorus.org.br</a>{{ $t('politicaDeDados.paragrafo9_2') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo10') }}<a href="mailto:contato@institutohorus.org.br" target="_blank">contato@institutohorus.org.br</a>.</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="criterios">{{ $t('politicaDeDados.criterio') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo11') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo12') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="avaliacao">{{ $t('politicaDeDados.avaliacao') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo13') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo14') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="validacao">{{ $t('politicaDeDados.validacao') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo15') }}</p>
          <p class="mt-2"><strong>{{ $t('politicaDeDados.paragrafo16') }}</strong></p>
          <ol class="mt-2">
            <li>{{ $t('politicaDeDados.marcador8') }}</li>
            <li>{{ $t('politicaDeDados.marcador9') }}</li>
            <li>{{ $t('politicaDeDados.marcador10') }}</li>
            <li>{{ $t('politicaDeDados.marcador11') }}</li>
            <li>{{ $t('politicaDeDados.marcador12') }}</li>
            <li>{{ $t('politicaDeDados.marcador13') }}</li>
            <li>{{ $t('politicaDeDados.marcador14') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo17') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador15') }}</li>
            <li>{{ $t('politicaDeDados.marcador16') }}</li>
            <li>{{ $t('politicaDeDados.marcador17') }}</li>
            <li>{{ $t('politicaDeDados.marcador18') }}</li>
            <li>{{ $t('politicaDeDados.marcador19') }}</li>
            <li>{{ $t('politicaDeDados.marcador20') }}</li>
          </ol>
          <p class="mt-2">{{ $t('politicaDeDados.paragrafo19') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador21') }}</li>
            <li>{{ $t('politicaDeDados.marcador22') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo20') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador23') }}</li>
            <li>{{ $t('politicaDeDados.marcador24') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo21') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="exclusao">{{ $t('politicaDeDados.exclusao') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo22') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo23') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo24') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador25') }}</li>
            <li>{{ $t('politicaDeDados.marcador26') }}</li>
            <li>{{ $t('politicaDeDados.marcador27') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo25') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'PoliticaDeDados',
  components: { InstitucionalHeaderSingle }
}
</script>
