import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    logged: null,
    preload: false,
    isMenuCollapsed: false,
    currentPage: 1,
    perPage: 10,
    countList: 0,
    dashboard: {
      aba: 1,
      currentPage: 1
    },
    beforeRouter: '',
    formSearch: {
      specialty_id: '0',
      contact_id: null,
      state_id: '',
      municipio_id: '',
      species_id: '',
      environment_id: ''
    },
    formSearchEspecies: {
      theme: null,
      fieldTaxonomy: 1,
      fieldBiologiaEcologia: 1,
      fieldInvasaoBiologia: 1,
      fieldOccurrence: 1,
      specieSelected: []
    },
    dataOrderBy: {}
  },
  mutations: {
    clearFormSearch: (state) => {
      state.formSearch = {
        specialty_id: '0',
        contact_id: null,
        state_id: '',
        municipio_id: '',
        species_id: '',
        environment_id: '',
        protected_area_id: ''
      }
      state.formSearchEspecies = {
        theme: null,
        fieldTaxonomy: 1,
        fieldBiologiaEcologia: 1,
        fieldInvasaoBiologia: 1,
        fieldOccurrence: 1,
        specieSelected: []
      }
      state.dataOrderBy = {}
    },
    setUser (state, user) {
      state.user = user

      if (user !== null) {
        const admin = 'administrator'
        var administrator = state.user.sysUser.groupUsers.filter(e => e.group_name === admin)
        if (administrator.length > 0) {
          state.user.perfilUser = admin
        } else {
          state.user.perfilUser = 'basic'
        }
      }

      let hasUser = false
      if (user) {
        hasUser = true
      }
      state.preload = hasUser
      state.logged = hasUser
      state.dashboard = {
        aba: 1,
        currentPage: 1
      }
    },
    login: state => {
      state.logged = true
    },
    logout: state => {
      state.logged = false
      state.user = {}
    },
    preload (store, value) {
      store.preload = value
    },
    onMenuCollapse (state, isMenuCollapsed) {
      if (isMenuCollapsed === undefined) {
        state.isMenuCollapsed = !state.isMenuCollapsed
      } else {
        state.isMenuCollapsed = isMenuCollapsed
      }
    }
  },
  actions: {
    showPreload (dispatch) {
      dispatch.commit('preload', true)
    },
    hidePreload (dispatch) {
      dispatch.commit('preload', false)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
