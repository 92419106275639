<template>
  <div>
    <div class="table-responsive full_table mt-0">
      <table class="table caption-top">
        <thead class="bg_table_header">
          <tr>
            <th class="col-md-3 py-3 pl-4">{{ $t('label.nomeCientifico') }}<button class="bt_order_asc bt_order" @click="orderBy(4)"></button><button class="bt_order_desc bt_order" @click="orderBy(-4)"></button></th>
            <th class="col-md-2 py-3">{{ $t('label.estado') }}<button class="bt_order_asc bt_order" @click="orderBy(1)"></button><button class="bt_order_desc bt_order" @click="orderBy(-1)"></button></th>
            <th class="col-md-2 py-3">{{ $t('label.municipio') }}<button class="bt_order_asc bt_order" @click="orderBy(2)"></button><button class="bt_order_desc bt_order" @click="orderBy(-2)"></button></th>
            <th class="col-md-3 py-3">{{ $t('label.referenciaLocal') }}<button class="bt_order_asc bt_order" @click="orderBy(3)"></button><button class="bt_order_desc bt_order" @click="orderBy(-3)"></button></th>
            <th class="col-md-2 py-3 pr-4 text-right">{{ $t('label.acoes') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.countList === 0"><td colspan="5" class="text-center">{{ $t('message.noRecords') }}</td></tr>
          <tr v-else v-for="item in items" :key="item.place_for_approval_id">
            <td class="py-3 pl-4">{{item.dataSpecies ? item.dataSpecies.scientific_name : '' }}</td>
            <td class="py-3">{{item.vocStates ? item.vocStates.state : '' }}</td>
            <td class="py-3">{{item.vocMunicipios ? item.vocMunicipios.municipio : '' }}</td>
            <td class="py-3">{{item.location}}</td>
            <td class="py-3">
              <ul class="acoes text-right pr-1">
                <li>
                  <a @click="action($actionEdit, item)"><span class="nao-validado"></span></a>
                </li>
                <li>
                  <a @click="action($actionDelete, item)" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'"><span class="apagar"></span></a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination v-on:searchTable="search()"/>
  </div>
</template>

<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
export default {
  name: 'OcorrenciasAppTable',
  components: { TablePagination },
  props: {
    items: Array
  },
  data: function () {
    return {
      dataOrderBy: {}
    }
  },
  methods: {
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        if (this.$route.name === 'AdminHome') {
          this.$store.state.dashboard.currentPage = this.$store.state.currentPage
        }
        this.$router.push({ name: 'AdminOcorrenciasAppForm', params: { id: item.place_for_approval_id } })
      }
    },
    delete: function (item) {
      axios.delete(`/DataPlacesForApproval/${item.place_for_approval_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$emit('search')
    },
    orderBy: function (num) {
      if (num === 1) {
        this.$store.state.dataOrderBy.OrderByStateAcs = true
        this.$store.state.dataOrderBy.OrderByStateDesc = !this.$store.state.dataOrderBy.OrderByStateAcs
      }
      if (num === -1) {
        this.$store.state.dataOrderBy.OrderByStateDesc = true
        this.$store.state.dataOrderBy.OrderByStateAcs = !this.$store.state.dataOrderBy.OrderByStateDesc
      }
      if (num === 2) {
        this.$store.state.dataOrderBy.OrderByMunicipioAcs = true
        this.$store.state.dataOrderBy.OrderByMunicipioDesc = !this.$store.state.dataOrderBy.OrderByMunicipioAcs
      }
      if (num === -2) {
        this.$store.state.dataOrderBy.OrderByMunicipioDesc = true
        this.$store.state.dataOrderBy.OrderByMunicipioAcs = !this.$store.state.dataOrderBy.OrderByMunicipioDesc
      }
      if (num === 3) {
        this.$store.state.dataOrderBy.OrderByLocationAcs = true
        this.$store.state.dataOrderBy.OrderByLocationDesc = !this.$store.state.dataOrderBy.OrderByLocationAcs
      }
      if (num === -3) {
        this.$store.state.dataOrderBy.OrderByLocationDesc = true
        this.$store.state.dataOrderBy.OrderByLocationAcs = !this.$store.state.dataOrderBy.OrderByLocationDesc
      }
      if (num === 4) {
        this.$store.state.dataOrderBy.OrderByScientific_nameAcs = true
        this.$store.state.dataOrderBy.OrderByScientific_nameDesc = !this.$store.state.dataOrderBy.OrderByScientific_nameAcs
      }
      if (num === -4) {
        this.$store.state.dataOrderBy.OrderByScientific_nameDesc = true
        this.$store.state.dataOrderBy.OrderByScientific_nameAcs = !this.$store.state.dataOrderBy.OrderByScientific_nameDesc
      }
      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
