<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===7 }" id="ocorrencias" role="tabpanel" aria-labelledby="ocorrencias-tab">
    <div class="accordion accordion-flush mt-3" id="aba-ocorrencia-toggle">
      <!-- add ocorrencia -->
      <div class="accordion-item" >
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#adicionar-ocorrencia" :aria-expanded="accordionEdit" aria-controls="adicionar-ocorrencia"
              :class="{ 'collapsed': !accordionEdit }" @click="clickAccordionEdit(true)" :disabled="byInstitucional && !accordionEdit">
              {{ byInstitucional ? $t('label.visualizarOcorrencia') : $t('label.addOcorrencia') }}
            </button>
          </h2>
          <div id="adicionar-ocorrencia" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#aba-ocorrencia-toggle" :class="{ 'show': accordionEdit }">
            <div class="accordion-body">
                <div class="row mb-3 row ">
                  <div class="col-md-6">
                      <div class="mt-3">
                        <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                        <v-select multiple :appendToBody="true" :filterable="false" @search="getContacts" :options="contactsList" v-model="relContactsSelected" label="surname" index="contact_id"
                          :reduce="r => r.contact_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': $v.relContactsSelected.$dirty && $v.relContactsSelected.$error }" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-pais" class="form-label">{{ $t('label.pais') }}</label>
                        <v-select :appendToBody="true" :options="vocCountryList" v-model="item.country_id" label="value" index="country_id" :disabled="byInstitucional"
                          :reduce="r => r.country_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-estado" class="form-label">{{ $t('label.estado') }}</label>
                        <v-select :appendToBody="true" :options="statesList" v-model="item.state_id" label="state" index="state_id" :disabled="byInstitucional"
                          :reduce="r => r.state_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.state}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.state}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-municipio" class="form-label">{{ $t('label.municipio') }}</label>
                        <v-select :appendToBody="true" :options="vocMunicipiosList" v-model="item.municipio_id" label="municipio" index="municipio_id" :disabled="byInstitucional"
                          :reduce="r => r.municipio_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.municipio}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.municipio}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-area-protegida" class="form-label">{{ $t('label.areaProtegida') }}</label>
                        <v-select :appendToBody="true" :options="vocProtectedAreaList" v-model="item.protected_area_id" label="value" index="protected_area_id"  :disabled="byInstitucional"
                        :reduce="r => r.protected_area_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-ref-local" class="form-label">{{ $t('label.referenciaLocal') }}</label>
                        <textarea id="add-ocorrencias-ref-local" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.location" :disabled="byInstitucional"></textarea>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-referencia" class="form-label">{{ $t('label.referencia') }}</label>
                        <v-select :appendToBody="true" :options="dataBibliographyList" v-model="item.bibliographic_reference_id" label="title" index="bibliography_id" :disabled="byInstitucional"
                          :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.title}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.title}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-grau-latitude" class="form-label">{{ $t('label.latitude') }} (ex. 27 35 06.43 S)</label>
                        <input type="text" class="form-control" id="add-ocorrencias-grau-latitude" v-model="item.latitude" :disabled="byInstitucional">
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-grau-longitude" class="form-label">{{ $t('label.longitude') }} (ex. 48 32 58 W)</label>
                        <input type="text" class="form-control" id="add-ocorrencias-grau-longitude" v-model="item.longitude" :disabled="byInstitucional">
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-coordenadas-correspondem" class="form-label">{{ $t('label.coordenadasCorrespondem') }}</label>
                        <v-select :appendToBody="true" :options="coordinatesSourceList" v-model="item.coordinates_source" label="value" index="id" :disabled="byInstitucional"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" v-show="item.coordinates_source === 1">
                        <v-select :appendToBody="true" :options="pointScopeList" v-model="item.point_scope" label="value" index="id"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" v-show="item.coordinates_source === 2">
                        <v-select :appendToBody="true" :options="pointList" v-model="item.point" label="value" index="id"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" id="dist-aproximada" v-show="item.coordinates_source === 2 && (item.point === 3 || item.point === '3')">
                          <label for="add-ocorrencias-distancia-aproximada" class="form-label">{{ $t('label.distanciaAproximada') }}</label>
                          <input type="text" class="form-control" id="dd-ocorrencias-distancia-aproximada" placeholder="Por ex. 50m" v-model="item.point_distance" :disabled="byInstitucional">
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="mt-3">
                        <label for="ocorrencias-ambiente" class="form-label">{{ $t('label.ambiente') }}</label>
                        <v-select :appendToBody="true" :options="vocEnvironmentsList" v-model="item.environment_id" label="value" index="environment_id"
                          :reduce="r => r.environment_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-bacia-hidrografica" class="form-label">{{ $t('label.baciaHidro') }}</label>
                        <input type="text" class="form-control" id="add-ocorrencias-bacia-hidrografica" v-model="item.watershed" :disabled="byInstitucional">
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-descricao-invasao" class="form-label">{{ $t('label.descricaoInvasao') }}</label>
                        <textarea id="add-ocorrencias-descricao-invasao" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.invasion_description" :disabled="byInstitucional"></textarea>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-abundancia" class="form-label">{{ $t('label.abundancia') }}</label>
                        <v-select :appendToBody="true" :options="vocLocalAbundanceList" v-model="item.local_abundance_id" label="value" index="local_abundance_id" :disabled="byInstitucional"
                          :reduce="r => r.local_abundance_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-situacao-populacional" class="form-label">{{ $t('label.situacaoPopulacional') }}</label>
                        <v-select :appendToBody="true" :options="vocPopulationSituationList" v-model="item.population_situation_id" label="value" index="population_situation_id" :disabled="byInstitucional"
                          :reduce="r => r.population_situation_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <label for="add-ocorrencias-manejo" class="form-label">{{ $t('label.manejo') }}</label>
                        <v-select :appendToBody="true" :options="vocManagementList" v-model="item.management_id" label="value" index="management_id" :disabled="byInstitucional"
                          :reduce="r => r.management_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                            <label for="data" class="form-label">{{ $t('label.dataEntrada') }}</label>
                            <input type="text" class="form-control" v-model="item.entry_date" disabled>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                            <label for="data" class="form-label">{{ $t('label.dataObservacao') }}</label>
                            <input type="text" class="form-control" v-model="item.obs_date" v-mask="['#####', '##/##/####']" :disabled="byInstitucional">
                        </div>
                      </div>
                      <div class="mt-5">
                        <label for="data" class="form-label">{{ $t('label.validado') }}</label>
                      </div>
                      <div class="mt-0">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="0" v-model="item.validated" :disabled="byInstitucional">
                            <label class="form-check-label" for="inlineRadio1">{{ $t('label.sim') }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="1" v-model="item.validated" :disabled="byInstitucional">
                            <label class="form-check-label" for="inlineRadio2">{{ $t('label.nao') }}</label>
                        </div>
                      </div>
                      <div class="mt-5">
                        <input class="form-check-input" type="checkbox" id="especie-museu-herbario" v-model="is_herbarium_museum" :disabled="byInstitucional">
                        <label class="form-check-label" for="inlineCheckbox1">{{ $t('label.especieMuseuHerbario') }}</label>
                        <div class="myClass p-4 rounded-3 border-1 mt-3" id="box-museu-id" v-show="is_herbarium_museum">
                            <div class="">
                              <label for="selecionar-tipo-ocorrencia" class="form-label">{{ $t('label.selecioneTipo') }}</label>
                              <v-select :appendToBody="true" :options="isHerbariumList" v-model="item.is_herbarium" label="value" index="id" :disabled="byInstitucional"
                                :reduce="r => r.id" :clearable="false" class="wf-select vue-select">
                                <template v-slot:selected-option="option">
                                  {{ `${option.value}` }}
                                </template>
                                <template v-slot:option="option">
                                  {{ `${option.value}` }}
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                  </template>
                                </template>
                              </v-select>
                              <div id="herbario-box">
                                <div v-show="item.is_herbarium === 'herbarium'" class="mt-3">
                                  <label for="add-ocorrencias-herbario" class="form-label">{{ $t('label.herbario') }}</label>
                                  <v-select :appendToBody="true" :filterable="false" @search="getVocMuseum" :options="vocMuseumList" v-model="item.herbarium_id" label="value" index="museum_id"
                                    :reduce="r => r.museum_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                    <template v-slot:selected-option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                      </template>
                                    </template>
                                  </v-select>
                                </div>
                                <div v-show="item.is_herbarium === 'museum'" class="mt-3">
                                  <label class="form-label">{{ $t('label.museu') }}</label>
                                  <input type="text" class="form-control" v-model="item.museum" :disabled="byInstitucional">
                                </div>
                              <template v-if="item.is_herbarium">
                                <div class="mt-3">
                                  <label for="add-ocorrencias-numero-exemplar" class="form-label">{{ $t('label.numeroExemplaInstituicao') }}</label>
                                  <input type="text" class="form-control" id="add-ocorrencias-numero-exemplar" v-model="item.specimen_number" :disabled="byInstitucional">
                                </div>
                                <div class="mt-3">
                                  <label for="add-ocorrencias-numero-exemplar-coletor" class="form-label">{{ $t('label.numeroExemplarColetor') }}</label>
                                  <input type="text" class="form-control" id="add-ocorrencias-numero-exemplar-coletor" v-model="item.copy_specimen_number_collector" :disabled="byInstitucional">
                                </div>
                                <div class="mt-3">
                                  <label for="add-ocorrencias-instituicao" class="form-label">{{ $t('label.instituicao') }}</label>
                                  <input type="text" class="form-control" id="add-ocorrencias-instituicao" v-model="item.copy_institution" :disabled="byInstitucional">
                                </div>
                                <div class="mt-3">
                                  <label for="add-ocorrencias-museu-pais" class="form-label">{{ $t('label.pais') }}</label>
                                  <v-select :appendToBody="true" :options="vocCountryList" v-model="item.copy_country_id" label="value" index="country_id" :disabled="byInstitucional"
                                    :reduce="r => r.country_id" :clearable="false" class="wf-select vue-select">
                                    <template v-slot:selected-option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                      </template>
                                    </template>
                                  </v-select>
                                </div>
                                <div class="mt-3">
                                  <label for="add-ocorrencias-cidade" class="form-label">{{ $t('label.cidade') }}</label>
                                  <input type="text" class="form-control" id="add-ocorrencias-cidade" v-model="item.copy_city" :disabled="byInstitucional">
                                </div>
                              </template>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="col-12 text-end col-12 text-end pb-4 pt-4" v-if="!byInstitucional">
                      <a @click="addOcorrencia(item)" class=" bt-add-registro">{{ item.place_id ? $t('label.alterarOcorrencia') : $t('label.addOcorrencia') }}</a>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <!-- fim add ocorrencia -->
      <!-- tabela ocorrencia -->
      <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#ocorrencias-vinculadas" :aria-expanded="accordionList" aria-controls="ocorrencias-vinculadas"
              :class="{ 'collapsed': !accordionList }" @click="clickAccordionList">
            {{ $t('label.ocorrenciasVinculadas') }}
            </button>
          </h2>
          <div id="ocorrencias-vinculadas" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#aba-ocorrencia-toggle" :class="{ 'show': accordionList }">
            <div class="accordion-body">
                <div class="row pb-0">
                  <div class="col-md-6">
                      <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelDistribution }">
                        <label for="add-ocorrencia-distribuicao-pais" class="form-label">{{ $t('label.distribuicaoPais') }}</label>
                        <button v-if="isPendingAnalysisRelDistribution" class="btn" type="button" @click="$emit('analysis', $t('label.distribuicaoPais'), 'relDistributionSelected', vocDistributionList, relDistributionSelected, oldRelDistributionSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select multiple :appendToBody="true" :options="vocDistributionList" v-model="relDistributionSelected" label="value" index="distribution_id"
                          :reduce="r => r.distribution_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="mb-3 mt-3 text-end">
                        <button class=" bt-mapa" id="veja-mapa" data-bs-toggle="modal" data-bs-target="#mapaOcorrencias" @click="setViewMap()">{{ $t('label.vejaMapaOcorrencias') }}</button>
                      </div>
                  </div>
                </div>
                <!-- num registros -->
                <div class="row mt-1 mt-xl-2 p-3 ">
                  <div class="col-auto d-none d-sm-block">
                      <p class="mb-0 mt-2">{{ $t('label.numRegistrosEncontrados') }}  <span class="laranja">{{$store.state.countList}}</span></p>
                  </div>
                  <div class="col-auto ml-auto text-right select_registro">
                    <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                      <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                      <option value="25">{{ $t('label.exibir25Reg') }}</option>
                      <option value="50">{{ $t('label.exibir50Reg') }}</option>
                      <option value="100">{{ $t('label.exibir100Reg') }}</option>
                    </select>
                  </div>
                </div>
                <!-- /num registros -->
                <!-- tabela -->
                <div class="row">
                  <div class="col-12 d-flex">
                    <div class="card flex-fill">
                      <div class="card-body box-dashboard p-0">
                        <div class="align-self-center">
                          <div class="table-responsive full_table mt-0">
                            <table class="table caption-top">
                              <thead class="bg_table_header">
                                <tr>
                                  <th class="col-md-1 py-3 pl-4">{{ $t('label.estado') }}</th>
                                  <th class="col-md-3 py-3">{{ $t('label.municipio') }}</th>
                                  <th class="col-md-3 py-3">{{ $t('label.areaProtegida') }}</th>
                                  <th class="col-md-3 py-3">{{ $t('label.referenciaLocal') }}</th>
                                  <th class="col-md-2 py-3 pr-4 text-right">{{ $t('label.acoes') }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="$store.state.countList === 0"><td colspan="5" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                                <tr v-else v-for="item in items" :key="item.place_id">
                                  <td class="py-3 pl-4">{{item.state}}</td>
                                  <td class="py-3">{{item.municipio}}</td>
                                  <td class="py-3">{{item.protected_area}}</td>
                                  <td class="py-3">{{item.location}}</td>
                                  <td class="py-3">
                                    <ul class="acoes text-right pr-1" v-if="!byInstitucional">
                                      <li>
                                        <a @click="actionValidar(item)" v-if="item.pending_analysis"><span class="nao-validado"></span></a>
                                        <template v-else>
                                          <a @click="actionValidar(item)" v-if="item.by_app"><span class="validado-app"></span></a>
                                          <a @click="actionValidar(item)" v-else><span class="validar"></span></a>
                                        </template>
                                      </li>
                                      <li>
                                        <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                                      </li>
                                      <li>
                                        <a @click="action($actionDelete, item)" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'"><span class="apagar"></span></a>
                                      </li>
                                    </ul>
                                    <ul class="acoes text-right pr-1" v-else>
                                      <li>
                                        <a @click="action($actionEdit, item)"><span class="visualizar"></span></a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <TablePagination v-on:searchTable="search()"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /tabela -->
            </div>
          </div>
      </div>
      <!-- fim tabela ocorrencia -->
    </div>
    <!-- modal maps -->
    <MapaOcorrencias :markers="markers" :viewMap="viewMap" :scientificName="form.scientific_name"/>
    <!-- fim modal maps -->
  </div>
</template>

<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
import { required } from 'vuelidate/lib/validators'
import MapaOcorrencias from './MapaOcorrencias.vue'
export default {
  name: 'EspeciesTabOcorrencias',
  components: { TablePagination, MapaOcorrencias },
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorOcorrencias: Boolean,
    onlyLoadValidator: Boolean,
    dataBibliographyList: Array,
    pendingAnalysis: Boolean
  },
  data () {
    return {
      viewMap: false,
      item: { is_herbarium: '', coordinates_source: null, point_scope: null, point: null },
      items: [],
      vocDistributionList: [],
      relDistributionSelected: [],
      oldRelDistributionSelected: [],
      contactsList: [],
      relContactsSelected: [],
      vocCountryList: [],
      statesList: [],
      vocMunicipiosList: [],
      vocEnvironmentsList: [],
      vocLocalAbundanceList: [],
      vocPopulationSituationList: [],
      vocManagementList: [],
      vocProtectedAreaList: [],
      pointList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.pointList1') },
        { id: 2, value: this.$t('label.pointList2') },
        { id: 3, value: this.$t('label.pointList3') }
      ],
      pointScopeList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.estado') },
        { id: 2, value: this.$t('label.municipioSede') },
        { id: 4, value: this.$t('label.municipioCentroide') },
        { id: 3, value: this.$t('label.areaProtegida') },
        { id: 5, value: this.$t('label.outro') }
      ],
      coordinatesSourceList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.coordinatesSourceList1') },
        { id: 2, value: this.$t('label.coordinatesSourceList2') }
      ],
      is_herbarium_museum: false,
      isHerbariumList: [
        { id: 'museum', value: this.$t('label.museu') },
        { id: 'herbarium', value: this.$t('label.herbario') }
      ],
      vocMuseumList: [],
      accordionList: true,
      accordionEdit: false,
      markers: []
    }
  },
  validations: {
    form: {},
    item: {},
    relContactsSelected: { required }
  },
  computed: {
    isPendingAnalysisRelDistribution: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelDistribution
      this.$emit('especiesTabOcorrenciasPending', result)
      return result
    },
    pendingRelDistribution: function () {
      let result = false
      if (this.relDistributionSelected.length !== this.oldRelDistributionSelected.length) {
        return true
      }
      this.oldRelDistributionSelected.forEach(e => {
        if (!this.relDistributionSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  watch: {
    'item.is_herbarium' (newValue) {
      if (newValue) {
        this.is_herbarium_museum = (this.item.is_herbarium === 'herbarium' || this.item.is_herbarium === 'museum')
      } else {
        this.is_herbarium_museum = false
      }
    },
    'loadValidatorOcorrencias' (newValue) {
      if (newValue) {
        this.$v.$touch()
        const obj = {
          relDistributionSelected: this.relDistributionSelected
        }
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v, obj)
      }
    },
    'form.species_id' (newValue) {
      if (newValue && this.aba === 7) {
        this.$store.state.currentPage = 1
        this.$store.state.perPage = 10
        this.search()
      }
    },
    'form.relDistribution' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelDistributionSelected()
        this.getOldRelDistributionSelected()
      }
    },
    'item.contact_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getContacts()
      }
    },
    'item.state_id' (newValue, oldValue) {
      // if (oldValue !== undefined) {
      //   this.item.municipio_id = null
      // }
      this.getVocMunicipios(newValue)
    },
    'item.coordinates_source' (newValue, oldValue) {
      if (newValue === 3) {
        this.item.coordinates_source = 2
      }
    },
    'item.point' (newValue, oldValue) {
      if (newValue) {
        this.item.point = Number(this.item.point)
      }
    },
    'item.herbarium_id' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getVocMuseum()
      }
    },
    'aba' () {
      if (this.aba === 7) {
        if (this.vocDistributionList.length === 0 ||
        this.contactsList.length === 0 ||
        this.vocCountryList.length === 0 ||
        this.statesList.length === 0 ||
        this.vocEnvironmentsList.length === 0 ||
        this.vocLocalAbundanceList.length === 0 ||
        this.vocPopulationSituationList.length === 0 ||
        this.vocManagementList.length === 0 ||
        this.vocMuseumList.length === 0) {
          this.$store.state.currentPage = 1
          this.$store.state.perPage = 10
          this.search()
          this.getVocDistribution()
          this.getContacts()
          this.getVocCountry()
          this.getStates()
          this.getVocEnvironments()
          this.getVocProtectedArea()
          this.getVocLocalAbundance()
          this.getVocPopulationSituation()
          this.getVocManagement()
          this.getVocMuseum()
        }
      }
    }
  },
  methods: {
    clickAccordionEdit: function (add) {
      this.viewMap = false
      if (add) {
        this.item = {
          is_herbarium: '',
          coordinates_source: null,
          point_scope: null,
          point: null,
          entry_date: this.$options.filters.formatDate(new Date())
        }
        this.relContactsSelected = []
      }
      this.accordionEdit = !this.accordionEdit
      if (this.accordionList) {
        this.accordionList = !this.accordionList
      }
    },
    clickAccordionList: function () {
      this.accordionList = !this.accordionList
      if (this.accordionEdit) {
        this.accordionEdit = !this.accordionEdit
      }
    },
    addOcorrencia: function (value) {
      this.$v.$touch()
      if (this.$v.item.$invalid || this.$v.relContactsSelected.$invalid) {
        return
      }
      value.relContacts = []
      this.relContactsSelected.forEach(el => {
        value.relContacts.push({ contact_id: el, place_id: value.place_id })
      })
      if (value.place_id) {
        value.log_update = JSON.stringify(value.log_update)
        axios.put('/DataPlaces', value)
          .then(() => {
            this.$toasted.global.saved()
            this.clickAccordionList()
            this.search()
          })
      } else {
        value.species_id = this.form.species_id
        axios.post('/DataPlaces', value)
          .then(() => {
            this.$toasted.global.saved()
            this.clickAccordionList()
            this.search()
          })
      }
    },
    action: async function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        await this.getDataPlacesById(item.place_id)
        this.clickAccordionEdit(false)
        if (this.byInstitucional) {
          window.scrollTo(0, 500)
        } else {
          window.scrollTo(0, 320)
        }
      }
    },
    getDataPlacesById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/DataPlaces/${id}`)
        .then(response => {
          this.item = response.data
          this.item.log_update = JSON.parse(this.item.log_update)
          this.getRelContactsSelected()
        })
    },
    actionValidar: function (item) {
      this.$router.push({ name: 'AdminOcorrenciasForm', params: { id: item.place_id } })
    },
    delete: function (item) {
      axios.delete(`/DataPlaces/${item.place_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      if (this.form.species_id) {
        this.$store.dispatch('showPreload')
        const params = {
          Page: this.$store.state.currentPage,
          PageSize: this.$store.state.perPage,
          Species_id: this.form.species_id
        }
        axios.get('/DataPlaces/getallgrid', { params })
          .then(response => {
            this.$store.state.currentPage = response.data.page
            this.$store.state.countList = response.data.count
            this.items = response.data.items
          })
      }
    },
    searchForMap: function () {
      if (this.form.species_id) {
        this.$store.dispatch('showPreload')
        const params = {
          Page: 1,
          PageSize: 9999,
          Species_id: this.form.species_id
        }
        axios.get('/DataPlaces/getall', { params })
          .then(response => {
            response.data.items.forEach(e => {
              if (e.latitude && e.longitude) {
                const lat = this.convertDegreeAngleToDouble(e.latitude)
                const lng = this.convertDegreeAngleToDouble(e.longitude)
                if (lat && lng) {
                  this.markers.push({
                    state: e.vocStates ? e.vocStates.state : '',
                    municipio: e.vocMunicipios ? e.vocMunicipios.municipio : '',
                    protected_area: e.protected_area,
                    lat: lat,
                    lng: lng,
                    latitude: e.latitude,
                    longitude: e.longitude,
                    pending_analysis: e.pending_analysis
                  })
                }
              }
            })
            this.viewMap = true
          })
      }
    },
    convertDegreeAngleToDouble: function (point) {
      try {
        // Example: 17 21 18 S
        const multiplier = (point.includes('S') || point.includes('W')) ? -1 : 1 // handle south and west

        point = point.match(/[0-9 .]/g).join('')
        const pointArray = point.split(' ')

        if (pointArray[0] && pointArray[1] && pointArray[2]) {
          const degrees = parseFloat(pointArray[0])
          const minutes = parseFloat(pointArray[1]) / 60
          const seconds = parseFloat(pointArray[2]) / 3600

          const result = (degrees + minutes + seconds) * multiplier
          return result
        }
      } catch (e) {
        console.log('Erro na conversão das coordenadas. Point -> ', point)
        console.log(e)
      }
      return null
    },
    getVocDistribution: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocdistribution/getall', { params })
        .then(response => {
          this.vocDistributionList = response.data.items.map(i => {
            const item = {
              distribution_id: i.distribution_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getRelDistributionSelected: function () {
      this.form.relDistribution.forEach(e => {
        if (!this.relDistributionSelected.includes(Number(e.distribution_id))) {
          this.relDistributionSelected.push(Number(e.distribution_id))
        }
      })
    },
    getOldRelDistributionSelected: function () {
      if (this.form.log_update && this.form.log_update.RelDistribution) {
        this.form.log_update.RelDistribution.forEach(e => {
          if (!this.oldRelDistributionSelected.includes(Number(e.Distribution_id))) {
            this.oldRelDistributionSelected.push(Number(e.Distribution_id))
          }
        })
      }
    },
    getRelContactsSelected: function () {
      this.relContactsSelected = []
      this.item.relContacts.forEach(e => {
        if (!this.relContactsSelected.includes(e.contact_id)) {
          this.relContactsSelected.push(Number(e.contact_id))
        }
      })
      this.getContacts()
    },
    getContacts: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        params.contact_id_array = this.relContactsSelected.join(',')
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.contact_id_array = this.relContactsSelected.join(',')
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
          })
      }
    },
    getVocCountry: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocCountry/getall', { params })
        .then(response => {
          this.vocCountryList = response.data.items.map(i => {
            const item = {
              country_id: i.country_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocMunicipios: function (stateId) {
      const params = {
        Page: 1,
        PageSize: 9999,
        State_id: stateId
      }
      this.$store.dispatch('showPreload')
      axios.get('/VocMunicipios/getall', { params })
        .then(response => {
          this.vocMunicipiosList = response.data.items
        })
    },
    getVocEnvironments: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocEnvironments/getall', { params })
        .then(response => {
          this.vocEnvironmentsList = response.data.items.map(i => {
            const item = {
              environment_id: i.environment_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocLocalAbundance: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocLocalAbundance/getall', { params })
        .then(response => {
          this.vocLocalAbundanceList = response.data.items.map(i => {
            const item = {
              local_abundance_id: i.local_abundance_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPopulationSituation: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocPopulationSituation/getall', { params })
        .then(response => {
          this.vocPopulationSituationList = response.data.items.map(i => {
            const item = {
              population_situation_id: i.situation_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocManagement: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocManagement/getall', { params })
        .then(response => {
          this.vocManagementList = response.data.items.map(i => {
            const item = {
              management_id: i.management_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocMuseum: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999,
        Museum_id: this.item.herbarium_id
      }
      if (search && search.length > 2) {
        switch (this.$i18n.locale) {
          case 'en':
            params.en = search
            break
          case 'es':
            params.es = search
            break
          case 'pt':
            params.pt = search
            break
        }
        loading(true)
        axios.get('/VocMuseum/getallcombo', { params })
          .then(response => {
            this.vocMuseumList = response.data.items.map(i => {
              const item = {
                museum_id: i.museum_id,
                value: ''
              }
              switch (this.$i18n.locale) {
                case 'en':
                  item.value = i.en
                  break
                case 'es':
                  item.value = i.es
                  break
                case 'pt':
                  item.value = i.pt
                  break
              }
              return item
            })
            loading(false)
          })
      } else if (!search) {
        axios.get('/VocMuseum/getallcombo', { params })
          .then(response => {
            this.vocMuseumList = response.data.items.map(i => {
              const item = {
                museum_id: i.museum_id,
                value: ''
              }
              switch (this.$i18n.locale) {
                case 'en':
                  item.value = i.en
                  break
                case 'es':
                  item.value = i.es
                  break
                case 'pt':
                  item.value = i.pt
                  break
              }
              return item
            })
          })
      }
    },
    getVocProtectedArea: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocProtectedArea/getall', { params })
        .then(response => {
          this.vocProtectedAreaList = response.data.items.map(i => {
            const item = {
              protected_area_id: i.protected_area_id,
              value: i.protected_area
            }
            return item
          })
        })
    },
    setViewMap: function () {
      this.markers = []
      this.searchForMap()
    }
  }
}
</script>
