<template>
  <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">{{title}} <em>{{nameEdit}}</em><em class="vermelho" v-if="pendingAnalysis"> ({{ $t('label.pendenteAnalise') }})</em></h3>
      </div>
      <div v-if="isAdd" class="col-auto ml-auto text-right mt-n1">
        <template v-if="permissionAllAdd || ($store.state.user && $store.state.user.perfilUser === 'administrator')">
          <router-link v-if="titleAdd" class="bt-add-registro" :to="`${this.$route.path}/${this.$actionNew}`">{{titleAdd}}</router-link>
      </template>
      </div>
      <div v-else class="col-auto ml-auto text-right mt-n1">
        <template v-if="permissionAllAdd || $store.state.user && $store.state.user.perfilUser === 'administrator' || this.$route.params.id !== this.$actionNew">
          <a @click="save" class="bt-add-registro">{{titleSave}}</a>
        </template>
        <a @click="excluir" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'" class="bt-add-registro bg-danger ml-1">{{ $t('label.excluir') }}</a>
        <a @click="cancel" class="btn btn-secondary ml-1">{{ $t('label.cancelarVoltar') }}</a>
      </div>
    </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: String,
    titleAdd: String,
    isAdd: Boolean,
    titleSave: String,
    nameEdit: String,
    pendingAnalysis: Boolean,
    permissionAllAdd: {
      Boolean: Boolean,
      default: true
    }
  },
  methods: {
    save: function () {
      this.$emit('onSave')
    },
    cancel: function () {
      this.$emit('onCancel')
    },
    excluir: function () {
      this.$emit('onExcluir')
    }
  }
}
</script>
