<template>
  <footer class=" border-top bg-azul-esc white">
    <div class="container">
      <div class="row pt-5 pb-5">
        <div class="col-xs col-sm-12 col-md-4 logo-rodape">
          <router-link class="text-muted" to="/"><img src="../../assets/institucional/img/logo/logo-horus-footer2.png"></router-link>
        </div>
        <div class="col-xs  col-sm-3 col-md-2">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/tutorial">{{ $t('label.tutorial') }}</router-link></li>
          </ul>
        </div>
        <div class="col-xs  col-sm-6 col-md-3">
          <ul>
            <li><router-link to="/rede-base-de-dados">Rede de Base de Dados</router-link></li>
            <li><router-link to="/politica-de-dados">Política de Dados</router-link></li>
            <li><router-link to="/comite-cientifico">Comitê Científico</router-link></li>
          </ul>
        </div>
        <div class="col-xs col-sm-3 col-md-3">
          <ul>
            <li><a @click="$i18n.locale = 'pt'">Português</a></li>
            <li><a @click="$i18n.locale = 'es'">Español</a></li>
            <li><a @click="$i18n.locale = 'en'">English</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm bg-preto copyright p-3">
      <p>© 2021 - Base de Dados Nacional de Espécies Exóticas Invasoras</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'InstitucionalFooter'
}
</script>
