<template>
    <div class="container logo-parceiros pb-5">
      <div class="row">
        <div class="col-sm">
          <h2 class="h5 laranja">{{ $t('areaParceiros.titulo') }}</h2>

          <div class="row mt-5">
            <div class="col-sm col-md-12 col-lg-6 col-xxl-4 pb-2">
              <h3 class="h5">{{ $t('label.argentina') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/apoio-dbbyf.png" ></li>
                <li><img src="../../assets/institucional/img/logo/apoio-gekko.png" ></li>
                <li><img src="../../assets/institucional/img/logo/apoio-uns.png" ></li>
              </ul>
            </div>
            <div class="col-sm col-md-12 col-lg-2 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.chile') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/apoio-cibet.png" ></li>
              </ul>
            </div>
            <div class="col-sm col-md-12 col-lg-4 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.costarica') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/apoio-cibet.png" ></li>
                <li><img src="../../assets/institucional/img/logo/apoio-ucr.png" ></li>
              </ul>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-sm col-md-12 col-lg-6 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.equador') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/logo_inabio4.png" ></li>
                <li><img src="../../assets/institucional/img/logo/logo_invasoras_ec.png"></li>
                <li><img src="../../assets/institucional/img/logo/logo_republica_del_ecuador.png" ></li>
                <li><img src="../../assets/institucional/img/logo/logo_gobierno_del_encontro.png"></li>
              </ul>
            </div>
            <div class="col-sm col-md-12 col-lg-6 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.honduras') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/apoio-herbario-tefh.png" ></li>
                <li><img src="../../assets/institucional/img/logo/apoio-unah1.png" ></li>
                <li><img src="../../assets/institucional/img/logo/apoio-unah2.png" ></li>
              </ul>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-sm col-md-12 col-lg-4 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.jamaica') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/logo_jamaica.png" ></li>
              </ul>
            </div>
            <div class="col-sm col-md-12 col-lg-2 col-xxl-2 pb-2">
              <h3 class="h5">{{ $t('label.paraguai') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/logo_Guyra_Paraguay.png" ></li>
              </ul>
            </div>
            <div class="col-sm col-md-12 col-lg-6 pb-2">
              <h3 class="h5">{{ $t('label.uruguai') }}</h3>
              <ul>
                <li><img src="../../assets/institucional/img/logo/logo_Uruguai_Comite_EEI.png" ></li>
                <li><img src="../../assets/institucional/img/logo/faculdad-del-ciencias-cor.png" ></li>
                <li><img src="../../assets/institucional/img/logo/logo_Uruguai_Ministerio_Ambiente.png" ></li>
                <li><img src="../../assets/institucional/img/logo/universidad-de-la-republica-uruguay-cor.png" ></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'InstitucionalParceiros'
}
</script>
<style>
  .logo-parceiros h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.15rem;
  }

  .logo-parceiros ul {
    padding-left: 0;
  }

  .logo-parceiros ul li {
    display: inline;
    list-style: none;
    margin: 5px 20px 5px 0;
  }
</style>
